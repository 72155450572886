import fallbackImage from '../../assets/map-photo.png';
import formatDate from 'date-fns/format';
import { MapPin } from 'phosphor-react';
import styled from '@emotion/styled';
import { colors } from '../../constants/colors';
import screenSizes from '../../constants/screenSizes';
import { parseISO } from 'date-fns';
import { useHistory } from 'react-router';

const Card = styled.div`
  background-color: #fff;
  box-shadow: 0 3px 9px -1px gray;
  padding: 10px;
  cursor: pointer;
  margin: 20px;
`;

const CardContent = styled.div`
  background-color: ${({ isPopup }) => (isPopup ? '#fff' : colors.GRAY_LIGHT)};
  border-radius: 6px;
  display: flex;
  min-height: 200px;
`;

const Image = styled.div`
  display: none;

  @media (min-width: ${screenSizes.large.min}) {
    display: block;
    border-radius: 8px;
    height: 200px;
    width: 200px;
    object-fit: cover;
    flex: 1 0 40%;
    background-image: url('${({ imageUrl }) => imageUrl && imageUrl}'), url(${fallbackImage});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  @media (min-width: ${screenSizes.large.min}) {
    height: 200px;
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  justify-content: space-around;
  color: #617077;
  font-size: 14px;
  flex: 1 0 60%;
`;

const Date = styled.div``;

const Place = styled.div`
  display: flex;
  color: #617077;
  align-items: center;
`;

const Title = styled.div`
  margin-right: 14px;
  line-height: 1.6;
`;

const EventType = styled.div`
  align-self: flex-start;
  padding: 5px 8px;
  background-color: ${colors.BLUE};
  text-align: center;
  color: #fff;
  margin-bottom: 5px;
`;

const City = styled.span`
  margin-left: 5px;
  font-weight: 600;
`;

const MapCardItem = ({ data = {}, mapData = {}, productsLength }) => {
  const history = useHistory();
  const { activation_type, area_event_name} = data;
  const { id, title, image_file, created_at } = mapData;

  const handleOnClick = () => {
    history.push({
      pathname: `/products/${id}`,
      state: { id },
    });
  };

  return (
    <Card onClick={handleOnClick} productsLength={productsLength}>
      <CardContent>
        <Image imageUrl={image_file}/>
        <Text>
          <Date>{created_at && formatDate(parseISO(created_at), 'dd MMM yyyy')}</Date>
          <Place>
            <MapPin size={22} weight="fill" color="#617077" />
            <City>{area_event_name}</City>
          </Place>
          <Title>{title}</Title>
          <EventType>{activation_type}</EventType>
        </Text>
      </CardContent>
    </Card>
  );
};

export default MapCardItem;
