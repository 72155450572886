export const routes = {
  HOME: '/',
  SEARCH: '/search',
  PRODUCTS: '/products',
  PRODUCT: '/products/:id',
  SERVICES: '/services',
  ABOUT_US: '/about-us',
  CONTACT: '/contact-us',
  PARTNERS: '/partners',
  HOME_TEST: '/home-test'
};
