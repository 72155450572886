import { routes } from './routes';
import { urls } from './urls';

export const pages = (services) => [
  {
    title: 'PRODUCTS',
    href: routes.PRODUCTS,
    items: [
      { title: 'Maps', href: `${routes.PRODUCTS}#map-section` },
      { title: 'Platforms & Apps', href: `${routes.PRODUCTS}#platforms` },
    ],
  },
  {
    title: 'SERVICES',
    items: services,
    href: routes.SERVICES,
  },
  {
    title: 'PROJECTS',
    href: urls.UNITAR_PROJECTS,
  },
  {
    title: 'PARTNERS',
    href: routes.PARTNERS,
  },
  {
    title: 'ABOUT US',
    href: routes.ABOUT_US,
  },
  {
    title: 'CONTACT US',
    href: routes.CONTACT,
  },
  {
    title: 'HOME TEST',
    href: routes.HOME_TEST,
  },
];
