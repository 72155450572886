import L from 'leaflet';
import { MapContainer, Marker, Popup, useMap } from 'react-leaflet';
import styled from '@emotion/styled';
import PopupMapItem from './PopupMapItem';
import { colors } from '../../constants/colors';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { DynamicMapLayer, TiledMapLayer } from 'react-esri-leaflet';
import { Globe } from 'phosphor-react';
import Control from 'react-leaflet-custom-control';
import cycloneIcon from '../../assets/activations/cyclone.svg';
import droughtIcon from '../../assets/activations/drought.svg';
import earthquakeIcon from '../../assets/activations/earthquake.svg';
import flashFloodIcon from '../../assets/activations/flash-flood.svg';
import heatwaveIcon from '../../assets/activations/heatwave.svg';
import tsunamiIcon from '../../assets/activations/tsunami.svg';
import floodIcon from '../../assets/activations/flood.svg';
import volcanoIcon from '../../assets/activations/volcano.svg';
import coldWaveIcon from '../../assets/activations/cold-wave.svg';
import famineIcon from '../../assets/activations/famine.svg';
import insectIcon from '../../assets/activations/insect-infestation.svg';
import snowIcon from '../../assets/activations/snow-avalanche.svg';
import techIcon from '../../assets/activations/technological-disaster.svg';
import tornadoIcon from '../../assets/activations/tornado.svg';
import windIcon from '../../assets/activations/violent-wind.svg';
import wildfireIcon from '../../assets/activations/wildfire.svg';
import environmentalImpact from '../../assets/activations/environmental-impact.svg';
import landslides from '../../assets/activations/landslide.svg';
import epidemic from '../../assets/activations/epidemic.svg';
import exercise from '../../assets/activations/exercise.svg';
import mudslide from '../../assets/activations/mudslide.svg';
import affected from '../../assets/activations/affected.svg';
import { activations } from '../../constants/activations';
import LoadingDots from '../../components/common/LoadingDots';
import Legend from './Legend';

const MapWrapper = styled.div`
  height: 524px;

  .iconStyles {
    background-color: ${colors.BLUE};
    border-radius: 20px;
    padding: 3px;
  }

  img.leaflet-marker-icon {
    background-color: ${colors.BLUE};
    border-radius: 20px;
    padding: 3px;
  }

  .marker-cluster-small {
    background-color: rgba(1, 67, 133, 0.6);
  }

  .marker-cluster-small div {
    background-color: rgba(1, 67, 133, 0.6);
    color: #fff;
  }

  .marker-cluster-medium {
    background-color: rgba(1, 67, 133, 0.6);
  }

  .marker-cluster-medium div {
    background-color: rgba(1, 67, 133, 0.6);
    color: #fff;
  }

  .marker-cluster-large {
    background-color: rgba(1, 67, 133, 0.6);
  }

  .marker-cluster-large div {
    background-color: rgba(1, 67, 133, 0.6);
    color: #fff;
  }
`;

const StyledPop = styled(Popup)`
  .leaflet-popup-content-wrapper {
    font-family: 'Open Sans', sans-serif;
    width: 300px; !important;
  }

  .leaflet-popup-tip-container {
    visibility: hidden;
  }
`;

const StyledMarker = styled(Marker)`
  .leaflet-marker-pane {
    img.leaflet-marker-icon {
      background-color: ${colors.BLUE}; !important;
    }
  }
`;

const SetViewButton = styled.button`
  background-color: #fff;
  border: none;
  display: flex;
  padding: 5px;
  cursor: pointer;
  appearance: none;
`;

const DefaultControl = () => {
  const defaultView = [16.263981, -0.027987];

  const map = useMap();

  const handleOnSetView = () => {
    map.setView(defaultView, 2);
  };

  return (
    <Control position="topleft">
      <SetViewButton color="inherit" onClick={handleOnSetView}>
        <Globe size={20} color="#333" />
      </SetViewButton>
    </Control>
  );
};

const MapResults = ({ products, isLoading }) => {
  const createIcon = (url) => {
    return new L.Icon({
      iconUrl: url,
      iconSize: [27, 27],
      iconAnchor: [13, 4],
      popupAnchor: [-3, 5],
      className: 'leaflet-icon',
    });
  };

  const getMarkerIcon = (type) => {
    switch (type) {
      case activations.FLOOD:
        return createIcon(floodIcon);
      case activations.TROPICAL_CYCLONE:
        return createIcon(cycloneIcon)
      case activations.WIND:
        return createIcon(windIcon);
      case activations.DROUGHT:
        return createIcon(droughtIcon);
      case activations.EARTHQUAKE:
        return createIcon(earthquakeIcon);
      case activations.FLASH_FLOOD:
        return createIcon(flashFloodIcon);
      case activations.HEAT_WAVE:
      case activations.EXTREME_TEMPERATURE:
        return createIcon(heatwaveIcon);
      case activations.TSUNAMI:
        return createIcon(tsunamiIcon);
      case activations.VOLCANO:
        return createIcon(volcanoIcon);
      case activations.WILD_FIRE:
      case activations.FIRE:
        return createIcon(wildfireIcon);
      case activations.ENVIRONMENTAL_IMPACT:
        return createIcon(environmentalImpact);
      case activations.LANDSLIDES:
        return createIcon(landslides);
      case activations.EPIDEMIC:
        return createIcon(epidemic);
      case activations.EXERCISE:
        return createIcon(exercise);
      case activations.MUD_SLIDE:
        return createIcon(mudslide);
      case activations.COLD_WAVE:
        return createIcon(coldWaveIcon);
      case activations.FAMINE:
        return createIcon(famineIcon);
      case activations.INSECT_INFESTATION:
        return createIcon(insectIcon);
      case activations.SNOW:
        return createIcon(snowIcon);
      case activations.TECH_DISASTER:
        return createIcon(techIcon);
      case activations.TORNADOES:
        return createIcon(tornadoIcon);
      default:
        return createIcon(affected);
    }
  };

  const ChangeView = ({ center, zoom }) => {
    const map = useMap();
    map.setView(center, zoom);
    return null;
  };

  if (isLoading) {
    return <LoadingDots />;
  }
  const device = window.innerWidth <= 479 ? 'mobile' : 'desktop';

  return (
    <div>
      <MapWrapper>
        <MapContainer
          id="map"
          center={[49.009952, 2.548635]}
          minZoom={device === 'desktop' ? 3 : 1}
          maxZoom={10}
          scrollWheelZoom={true}
          style={{ zIndex: 0 }}
          zoomControl={true}
          attributionControl={false}
          maxBounds={[
            [-90, -180],
            [90, 180],
          ]}
        >
          <DynamicMapLayer url={'https://cernunosat74.cern.ch/server/rest/services/UN_Basemap_LineMapService/MapServer/'}/>
          <TiledMapLayer
            url="https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer"
            noWrap={true}
            bounds={[
              [-90, -180],
              [90, 180],
            ]}
          />
          <Legend />
          <ChangeView center={[16.263981, -0.027987]} zoom={2} />
          <MarkerClusterGroup showCoverageOnHover={false}>
            {products?.map(
              ({ map_event, activation_type, latitude, longitude, area_event_name }) => (
                <StyledMarker
                  position={[latitude, longitude]}
                  key={`products-map-${map_event.id}`}
                  icon={getMarkerIcon(activation_type)}
                >
                  <StyledPop maxWidth="380">
                    <div id={`products-map-${map_event.id}`}>
                      <PopupMapItem
                        title={map_event.title}
                        created_at={map_event.created_at}
                        id={map_event.id}
                        area_event_name={area_event_name}
                      />
                    </div>
                  </StyledPop>
                </StyledMarker>
              ),
            )}
          </MarkerClusterGroup>
          <DefaultControl />
        </MapContainer>
      </MapWrapper>
    </div>
  );
};

export default MapResults;
