import styled from '@emotion/styled';

const LoadingDotsWrapper = styled.div`
  span {
    animation-duration: 1.2s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-name: blink;
    color: #55b4ff;
    font-size: 3.125rem;
  }

  span:nth-child(2) {
    animation-delay: 0.2s;
    color: #2884c9;
  }

  span:nth-child(3) {
    animation-delay: 0.4s;
    color: #14518e;
  }

  @keyframes blink {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
`;

const LoadingDots = () => (
  <LoadingDotsWrapper>
    <span>&#9679;</span>
    <span>&#9679;</span>
    <span>&#9679;</span>
  </LoadingDotsWrapper>
);

export default LoadingDots;
