import { Fragment, useEffect, useState } from 'react';
import fallbackImage from '../../assets/newsstories-fallback-min.jpg';
import ContentWrapper from '../../components/ContentWrapper';
import SectionTitle from '../../components/common/SectionTitle';
import styled from '@emotion/styled';
import screenSizes from '../../constants/screenSizes';
import Carousel from './Carousel';
import Button from '../../components/common/Button';
import { Timeline } from 'react-twitter-widgets';
import axios from 'axios';
import { colors } from '../../constants/colors';
import { urls } from '../../constants/urls';

const Section = styled.section`
  @media (min-width: ${screenSizes.medium.min}) {
    padding: 20px 0;
  }
`;

const Item = styled.div`
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)),
    url('${({ image }) => image && image}'), url(${fallbackImage});
  background-repeat: no-repeat;
  height: 400px;
  background-size: cover;
  cursor: pointer;

  @media (min-width: ${screenSizes.large.min}) {
    height: 500px;
  }
`;

const Content = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 70%;
  color: #fff;
  margin-left: 15%;

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 16px;
    width: 50%;
    margin-left: 10%;
  }
`;

const Title = styled.h2`
  font-size: 18px;
  font-weight: 700;

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 28px;
  }
`;

const Description = styled.div`
  font-size: 16px;

  @media (min-width: ${screenSizes.medium.min}) {
    width: 100%;
    font-size: 18px;
  }
`;

const Buttons = styled.div`
  display: flex;
  margin-top: 40px;

  button:first-of-type {
    margin-right: 10px;
  }
`;

const TwitterWrapper = styled.div`
  margin-top: 20px;

  @media (min-width: ${screenSizes.large.min}) {
    margin-top: 0;
    width: 40%;
  }
`;

const NewsWrapper = styled.div`
  padding: 30px 0;
  display: flex;
  flex-direction: column;

  @media (min-width: ${screenSizes.large.min}) {
    flex-direction: row;
  }
`;

const Category = styled.div`
  font-size: 12px;
  position: absolute;
  padding: 10px;
  top: 10px;
  background-color: ${colors.BLUE};
  color: #fff;
  font-weight: 700;

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 14px;
  }
`;

const CarouselItem = ({ title, description, category, image, link }) => {
  const handleOnItemClick = () => {
    window.open(link, '_blank');
  };

  const handleOnCategoryClick = () => {
    if (category === 'Stories') {
      window.open(urls.UNITAR_STORIES, '_blank');
    } else {
      window.open(urls.UNITAR_NEWS, '_blank');
    }
  };

  return (
    <Item image={image}>
      <Content>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <Buttons>
          <Button variant="white" type="square" onClick={handleOnItemClick}>
            <span>{category === 'Stories' ? 'SEE THIS STORY' : 'SEE NEWS'}</span>
          </Button>
          <Button variant="ghost" type="square" onClick={handleOnCategoryClick}>
            <span>{category === 'Stories' ? 'SEE ALL STORIES' : 'SEE ALL NEWS'}</span>
          </Button>
        </Buttons>
      </Content>
      <Category>{category === 'Stories' ? 'IMPACT STORY' : 'NEWS'}</Category>
    </Item>
  );
};

const NewsAndStories = () => {
  const [newsAndStories, setNewsAndStories] = useState(null);

  useEffect(() => {
    axios
      .get('/news_stories')
      .then((response) => {
        setNewsAndStories(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Section>
      <ContentWrapper>
        <SectionTitle text="NEWS & STORIES" />
          <NewsWrapper>
            {newsAndStories && (
                <Carousel>
                  {newsAndStories.map((item, index) => (
                    <Fragment key={`item-${index}`}>
                      <CarouselItem {...item} />
                    </Fragment>
                  ))}
                </Carousel>
            )}
            <TwitterWrapper>
              <Timeline
                dataSource={{
                  sourceType: 'profile',
                  screenName: 'unosat',
                }}
                options={{
                  height: '500',
                  size: 'large',
                  dnt: true,
                  chrome: "nofooter"
                }}
              />
            </TwitterWrapper>
          </NewsWrapper>
      </ContentWrapper>
    </Section>
  );
};

export default NewsAndStories;
