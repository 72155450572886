import styled from '@emotion/styled';
import screenSizes from '../../constants/screenSizes';
import { routes } from '../../constants/routes';
import { urls } from '../../constants/urls';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router';

const Item = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 5px;

  @media (min-width: 1070px) {
    margin: 0 8px;
  }
`;

const Title = styled.a`
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  font-weight: 600;
  margin-bottom: 10px;
`;

const SubItem = styled.a`
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  line-height: 1.4;

  &:hover .sub-sub-items {
    display: block;
  }
`;

const SubSubItems = styled.div`
  display: none;
  padding-left: 20px;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  line-height: 1.4;
`;

const Wrapper = styled.div`
  display: none;

  @media (min-width: ${screenSizes.large.min}) {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    font-size: 15px;
    padding-top: 24px;
  }
`;

const FooterDesktop = () => {
  const [services, setServices] = useState(null);
  const history = useHistory()

  useEffect(() => {
    axios
      .get('/our_services')
      .then((response) => {
        setServices(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const transformServices = services
    ?.sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
    .map((service) => {
      return {
        title: service.title,
        href: routes.SERVICES,
        section: service.title,
      };
    });

  return (
    <Wrapper>
      <Item>
        <Title href={routes.PRODUCTS}>PRODUCTS</Title>
        <SubItem href={`${routes.PRODUCTS}#analyses`}>Analyses</SubItem>
        <SubItem href={`${routes.PRODUCTS}#map-section`}>Maps</SubItem>
        <SubItem href={`${routes.PRODUCTS}#platforms`}>Platforms & Apps</SubItem>
      </Item>
      {/* <Item>
        <Title href={routes.SERVICES}>SERVICES</Title>
        {transformServices?.map(({ title, href }, index) => (
          <SubItem key={index} onClick={()=> history.push(routes.SERVICES, { selectedService: title })}>
            {title}
          </SubItem>
        ))}
      </Item> */}
      <Item>
        <Title href={routes.SERVICES}>SERVICES</Title>
        {transformServices?.map(({ title, href }, index) => (
          <SubItem key={index} onClick={() => history.push(routes.SERVICES, { selectedService: title })}>
            {title}
            {title === "Capacity Development" && (
              <SubSubItems className="sub-sub-items">
                <div>- Training Courses</div>
                <div>- Platforms</div>
              </SubSubItems>
            )}
          </SubItem>
        ))}
      </Item>
      <Item>
        <Title href={urls.UNITAR_PROJECTS} target="_blank" rel="noreferrer">
          PROJECTS
        </Title>
      </Item>
      <Item>
        <Title href={routes.PARTNERS}>PARTNERS</Title>
      </Item>
      <Item>
        <Title href={routes.ABOUT_US}>ABOUT US</Title>
      </Item>
      <Item>
        <Title href={routes.CONTACT}>CONTACT US</Title>
      </Item>
    </Wrapper>
  );
};

export default FooterDesktop;
