import { useHistory } from 'react-router';
import styled from '@emotion/styled';
import emergencyMappingIcon from '../../assets/home-emergency-mapping-min.svg';
import Button from '../../components/common/Button';
import ContentWrapper from '../../components/ContentWrapper';
import { colors } from '../../constants/colors';
import screenSizes from '../../constants/screenSizes';
import SectionTitle from '../../components/common/SectionTitle';
import { routes } from '../../constants/routes';
import Media from 'react-media';

const Section = styled.section`
  width: 100%;
  font-size: 16px;

  @media (min-width: ${screenSizes.medium.min}) {
    padding: 20px 0;
    font-size: 18px;
  }
`;

const Wrapper = styled(ContentWrapper)`
  display: flex;
  align-items: center;
`;

const Description = styled.p`
  color: ${colors.BLUE};
  margin-left: 13px;
`;

const Subtitle = styled.p`
  color: ${colors.BLUE};
  padding: 20px 0;
  margin-left: 13px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${screenSizes.medium.min}) {
    padding-right: 20px;
  }

  @media (min-width: ${screenSizes.large.min}) {
    padding-right: 100px;
  }
`;

const Image = styled.img`
  object-fit: contain;
  width: 290px;
  align-self: center;

  @media (min-width: ${screenSizes.medium.min}) {
    width: 350px;
  }

  @media (min-width: ${screenSizes.large.min}) {
    width: 440px;
    align-self: flex-end;
  }
`;

const ButtonWrapper = styled.div`
  margin-left: 13px;
`;

const EmergencyMapping = ({ isButtonVisible = false }) => {
  const history = useHistory();
  const handleOnClick = () => history.push(routes.PRODUCTS);

  return (
    <Section>
      <Wrapper>
        <Content>
          <SectionTitle text="EMERGENCY MAPPING" />
          <Description>
            Since 2003, UNOSAT provides satellite image analysis during humanitarian emergencies
            related to disasters, complex emergencies and conflict situations.
          </Description>
          <Media query={`(max-width: ${screenSizes.small.max})`}>
            {(matches) => matches && <Image src={emergencyMappingIcon} />}
          </Media>
          {isButtonVisible && (
            <>
              <Subtitle>To see the latest products, click below. </Subtitle>
              <ButtonWrapper>
                <Button size="long" variant="dark" onClick={handleOnClick}>
                  PRODUCTS
                </Button>
              </ButtonWrapper>
            </>
          )}
        </Content>
        <Media query={`(min-width: ${screenSizes.medium.min})`}>
          {(matches) => matches && <Image src={emergencyMappingIcon} />}
        </Media>
      </Wrapper>
    </Section>
  );
};

export default EmergencyMapping;
