import { Switch } from 'react-router-dom';
import { routes } from '../../constants/routes';
import Route from './Route';
import ScrollToTop from '../../utils/scrolling';
import OurPartnersPage from '../../pages/partners/OurPartnersPage';
import ServicesPage from '../../pages/services/ServicesPage';
import HomePage from '../../pages/home/HomePage';
import ProductsPage from '../../pages/products/ProductsPage';
import ProductDetailsPage from '../../pages/products/product/ProductDetails';
import ContactUsPage from '../../pages/contact-us/ContactUsPage';
import SearchPage from '../../pages/search/SearchPage';
import AboutUsPageV2 from '../../pages/about/AboutUsPageV2';
import HomeTest from '../../pages/new-design/HomePage';

const Content = () => (
  <>
    <ScrollToTop />
    <Switch>
      {routes.HOME && <Route exact path={routes.HOME} component={HomePage} />}
      {routes.SEARCH && <Route exact path={routes.SEARCH} component={SearchPage} />}
      {routes.ABOUT_US && <Route exact path={routes.ABOUT_US} component={AboutUsPageV2} />}
      {routes.PARTNERS && <Route exact path={routes.PARTNERS} component={OurPartnersPage} />}
      {routes.PRODUCTS && <Route exact path={routes.PRODUCTS} component={ProductsPage} />}
      {routes.PRODUCT && <Route exact path={routes.PRODUCT} component={ProductDetailsPage} />}
      {routes.SERVICES && <Route exact path={routes.SERVICES} component={ServicesPage} />}
      {routes.CONTACT && <Route exact path={routes.CONTACT} component={ContactUsPage} />}
      {routes.HOME_TEST && <Route exact path={routes.HOME_TEST} component={HomeTest} />}
    </Switch>
  </>
);

export default Content;
