import React from 'react';
import { createControlComponent } from '@react-leaflet/core';
import L from 'leaflet';

const createLegendControl = () => {
  const legend = L.control({ position: 'bottomright' });

  legend.onAdd = () => {
    const div = L.DomUtil.create('div', 'info legend');

    const styles = {
      container: {
        backgroundColor: 'white',
        padding: '6px 8px',
        marginBottom: '5px',
        boxShadow: '0 0 15px rgba(0, 0, 0, 0.2)',
        borderRadius: '5px'
      },
      icon: {
        width: '18px',
        height: '18px',
        float: 'left',
        marginRight: '8px',
        opacity: '0.7'
      }
    };

    const labels = [
      '<i style="background:#3E8EDE; width:18px; height:18px; float:left; margin-right:8px; opacity:0.7;"></i> Active',
      '<i style="background:#2D5B81; width:18px; height:18px; float:left; margin-right:8px; opacity:0.7;"></i> Inactive'
    ];

    div.innerHTML = labels.join('<br>');
    Object.assign(div.style, styles.container);
    return div;
  };

  return legend;
};

const Legend = createControlComponent(createLegendControl);

export default Legend;
