import { useEffect, useState } from 'react';
import axios from 'axios';
import styled from '@emotion/styled';
import { colors } from '../../../constants/colors';
import MoreMapsItem from './MoreMapsItem';
import screenSizes from '../../../constants/screenSizes';
import ProductDetailsLarge from './ProductDetailsLarge';
import { useParams } from 'react-router-dom';
import ContentWrapper from '../../../components/ContentWrapper';

const MoreMapsSection = styled.div`
  padding: 30px 0;

  @media (min-width: ${screenSizes.medium.min}) {
    padding: 100px 0;
  }
`;

const MoreMapsTitle = styled.div`
  font-size: 15px;
  display: flex;
  align-items: center;
`;

const Text = styled.div`
  background-color: ${colors.BLUE};
  padding: 12px 10px 10px;
  width: 180px;
  color: #fff;
`;

const Divider = styled.div`
  content: '';
  border-top: 2px solid ${colors.BLUE};
  width: 100%;
  margin-left: 20px;
`;

const MoreMapsWrapper = styled.div`
  margin-top: 20px;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(1, minmax(0, 1fr));

  @media (min-width: 501px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (min-width: ${screenSizes.large.min}) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
`;

const ProductDetails = () => {
  const [productData, setProductData] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState(null);
  const [coordinates, setCoordinates] = useState({ latitude: null, longitude: null });

  const params = useParams();

  useEffect(() => {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'X-Requested-With',
    };

    axios.get(`/our_products/${params.id}`, { headers }).then((response) => {
      setProductData(response?.data?.map_event);
      setRelatedProducts(response?.data?.related_products);
      setCoordinates({
        latitude: response?.data?.latitude,
        longitude: response?.data?.longitude,
      });
    });
  }, [params.id]);

  const limitOfRelatedProducts = window.innerWidth <= 1100 ? 3 : 4;

  return (
    <div>
      <ContentWrapper>
        {productData && (
          <ProductDetailsLarge
            productData={productData}
            lat={coordinates.latitude}
            lon={coordinates.longitude}
          />
        )}
        {relatedProducts?.length > 0 && (
          <MoreMapsSection>
            <MoreMapsTitle>
              <Text>MORE PRODUCTS</Text>
              <Divider />
            </MoreMapsTitle>
            <MoreMapsWrapper>
              {relatedProducts.slice(0, limitOfRelatedProducts).map((item, index) => (
                <MoreMapsItem {...item} key={`related-product-${index}`} />
              ))}
            </MoreMapsWrapper>
          </MoreMapsSection>
        )}
      </ContentWrapper>
    </div>
  );
};

export default ProductDetails;
