import { Children, cloneElement, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { CaretLeft, CaretRight } from 'phosphor-react';
import { colors } from '../../constants/colors';
import screenSizes from '../../constants/screenSizes';

const CarouselWrapper = styled.div`
  overflow-x: hidden;
  position: relative;

  @media (min-width: ${screenSizes.medium.min}) {
    max-width: 800px;
  }

  @media (min-width: ${screenSizes.large.min}) {
    max-width: 640px;
  }

  @media (min-width: ${screenSizes.xlarge.min}) {
    max-width: 940px;
  }
`;

const SlidesWrapper = styled.div`
  transform: ${({ activeSlide }) => `translateX(-${activeSlide * 100}%)`};
  transition: transform 0.3s;
  display: flex;
`;

const Slide = styled.div`
  flex: 1 0 100%;
`;

const Arrow = styled.button`
  position: absolute;
  appearance: none;
  background: none;
  border: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  top: 40%;
  ${({ rightPosition }) => rightPosition && `left: 85%`};

  @media (min-width: ${screenSizes.medium.min}) {
    ${({ rightPosition }) => rightPosition && `left: ${rightPosition}%`};
  }
`;

const Indicators = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-right: -8px;
  margin-top: 20px;
`;

const Indicator = styled.div`
  background: ${({ isActive }) => (isActive ? colors.BLUE : '#fff')};
  border: 1px solid ${colors.BLUE};
  border-radius: 24px;
  cursor: pointer;
  display: block;
  height: 12px;
  margin-right: 8px;
  width: 12px;
`;

const Carousel = ({ children }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [paused, setPaused] = useState(false);
  const slidesCount = Children.count(children);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) {
        if (activeSlide === slidesCount - 1) {
          goToSlide(0);
        } else {
          goToSlide(activeSlide + 1);
        }
      }
    }, 5000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  const goToSlide = (index) => {
    if (index < 0) {
      index = 0;
    } else if (index >= slidesCount) {
      index = slidesCount - 1;
    }

    setActiveSlide(index);
  };

  return (
    <CarouselWrapper onMouseEnter={() => setPaused(true)} onMouseLeave={() => setPaused(false)}>
      <SlidesWrapper activeSlide={activeSlide}>
        {Children.map(children, (child) => (
          <Slide>{cloneElement(child)}</Slide>
        ))}
      </SlidesWrapper>
      <div>
        <Arrow onClick={() => goToSlide(activeSlide === 0 ? slidesCount - 1 : activeSlide - 1)}>
          <CaretLeft size={52} color="#fff" />
        </Arrow>
        <Arrow
          onClick={() => goToSlide(activeSlide === slidesCount - 1 ? 0 : activeSlide + 1)}
          rightPosition={70}
        >
          <CaretRight size={52} color="#fff" />
        </Arrow>
      </div>
      <Indicators>
        {Children.map(children, (_, index) => (
          <Indicator
            data-testid={`indicator-${index}`}
            isActive={activeSlide >= index && activeSlide < index + 1}
            onClick={() => goToSlide(index)}
          />
        ))}
      </Indicators>
    </CarouselWrapper>
  );
};

export default Carousel;
