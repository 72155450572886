import fallbackImage from '../../../assets/map-photo.png';
import formatDate from 'date-fns/format';
import { parseISO } from 'date-fns';
import Button from '../../../components/common/Button';
import styled from '@emotion/styled';
import screenSizes from '../../../constants/screenSizes';
import { colors } from '../../../constants/colors';
import { routes } from '../../../constants/routes';
import { useHistory } from 'react-router';
import { ArrowCircleLeft, FileText } from 'phosphor-react';
import { urls } from '../../../constants/urls';
import { useEffect, useState } from 'react';
import axios from 'axios';
import DownloadLinks from './DownloadLinks';
import Media from 'react-media';
import { MapContainer, Marker } from 'react-leaflet';
import { DynamicMapLayer, TiledMapLayer } from 'react-esri-leaflet';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  @media (min-width: ${screenSizes.large.min}) {
    flex-direction: row;
  }
`;

// const ImageDesktop = styled.div`
//   display: none;

//   @media (min-width: ${screenSizes.large.min}) {
//     display: block;
//     width: 300px;
//     border-radius: 10px;
//     margin-top: 20px;
//     flex: 0 0 30%;
//     background-image: url('${({ imageUrl }) => imageUrl && imageUrl}'), url(${fallbackImage});
//     background-repeat: no-repeat;
//     background-size: cover;
//     height: 300px;
//   }
// `;

const ImageDesktopWrapper = styled.div`
  display: none;

  @media (min-width: ${screenSizes.large.min}) {
    display: block;
    width: 100%;
    border-radius: 10px;
    margin-top: 20px;
    flex: 0 0 30%;
    height: 100%;
    overflow: hidden;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
`;

const ImageDesktop = ({ imageUrl, href, target }) => {
  return (
    <a href={href} target={target} style={{ display: 'block', textDecoration: 'none', width: '300px', height: '300px', flex: '0 0 30%' }}>
      <ImageDesktopWrapper>
        <StyledImage src={imageUrl || fallbackImage} alt="Image" />
      </ImageDesktopWrapper>
    </a>
  );
};

// const ImageMobile = styled.div`
//   display: block;
//   border-radius: 10px;
//   margin-top: 20px;
//   background-image: url('${({ imageUrl }) => imageUrl && imageUrl}'), url(${fallbackImage});
//   background-repeat: no-repeat;
//   background-size: cover;
//   height: 230px;

//   @media (min-width: 600px) {
//     width: 40%;
//   }

//   @media (min-width: ${screenSizes.large.min}) {
//     display: none;
//   }
// `;

const ImageMobileWrapper = styled.div`
  display: block;
  border-radius: 10px;
  margin-top: 20px;
  height: 230px;
  overflow: hidden; /* To handle border-radius with the img tag */

  @media (min-width: 600px) {
    width: 40%;
  }

  @media (min-width: ${screenSizes.large.min}) {
    display: none;
  }
`;

const StyledMobileImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
`;

const ImageMobile = ({ imageUrl }) => {
  return (
    <ImageMobileWrapper>
      <StyledMobileImage src={imageUrl || fallbackImage} alt="Image" />
    </ImageMobileWrapper>
  );
};

const Content = styled.div`
  font-size: 18px;
  flex: 0 0 50%;

  @media (min-width: ${screenSizes.large.min}) {
    padding: 0 20px;
  }

  @media (min-width: ${screenSizes.xlarge.min}) {
    padding: 0 50px;
  }
`;

const ExtraInformation = styled.div`
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  border-left: 2px solid ${colors.BLUE};
  height: fit-content;
  width: 100%;

  @media (min-width: 600px) {
    width: 60%;
  }

  @media (min-width: ${screenSizes.large.min}) {
    flex: 0 0 20%;
  }
`;

const Title = styled.h3`
  font-size: 24px;
  font-weight: 700;
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 1.3;
  margin-top: 8px;
`;

const Item = styled.div`
  margin: 10px 0;
  font-weight: 600;
  color: #333;
`;

const FeedbackItem = styled.div`
  margin: 10px 0;
  display: flex;
  color: ${colors.BLUE};
  align-items: center;
`;

const FeedbackText = styled.a`
  font-weight: 700;
  color: ${colors.BLUE};
  text-decoration: none;
  margin-top: 4px;
  font-size: 15px;
`;

const ButtonWrapper = styled.div`
  margin: 40px 0;
`;

const Sources = styled.div`
  margin-top: 20px;
  line-height: 1.8;
  border-left: 2px solid ${colors.BLUE};
  padding-left: 15px;

  @media (min-width: ${screenSizes.medium.min}) {
    margin-top: 0;
  }
`;

const ExtraWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 60%;
  padding-bottom: 20px;

  @media (min-width: ${screenSizes.large.min}) {
    padding-left: 20px;
  }

  @media (min-width: ${screenSizes.xlarge.min}) {
    padding-left: 50px;
  }
`;

const GoBackButton = styled.div`
  cursor: pointer;
  width: 40px;
`;

const EmptyDiv = styled.div`
  flex: 0 0 30%;
`;

const ImageInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 600px) {
    flex-direction: row;
  }
`;

const ProductDetailsLarge = ({ productData = {}, lat, lon }) => {
  console.log(productData);
  const history = useHistory();
  const {
    title,
    description,
    sources,
    id,
    created_at,
    glide,
    wmap_link,
    gdp_link,
    shp_link,
    image_file,
    kml_link,
    wms_link,
    pdf_name,
    excel_table,
  } = productData;
  // const [pdf, setPdf] = useState(null);
  // const [excelFile, setExcelFile] = useState('');

  // useEffect(() => {
  //   axios
  //     .get(`/our_products/${id}/pdf`, { responseType: 'blob' })
  //     .then((response) => {
  //       const file = new Blob([response.data], { type: 'application/pdf' });
  //       const fileURL = URL.createObjectURL(file);
  //       setPdf(fileURL);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, [id]);

  // useEffect(() => {
  //   axios
  //     .get(`/our_products/${id}/excel`, { responseType: 'blob' })
  //     .then((response) => {
  //       const file = new Blob([response.data], { type: 'text/csv' });
  //       const fileURL = URL.createObjectURL(file);
  //       setExcelFile(fileURL);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, [id]);

  const handleContactUsClick = () => {
    history.push(routes.CONTACT);
  };

  const goBack = () => {
    history.goBack();
  };

  const transformedSources = sources?.split('\n');

  return (
    <>
      <GoBackButton onClick={goBack}>
        <ArrowCircleLeft size={38} color={colors.DARK_BLUE} weight="thin" />
      </GoBackButton>
      <Wrapper isReverse>
        <ImageDesktop
          imageUrl={image_file}
          href={`/static/unosat_filesystem/${id}/${pdf_name}`}
          target="_blank"
        />
        <Content>
          <Title>{title}</Title>
          <div>
            {description?.split('\n')?.map((item) => (
              <Description>{item}</Description>
            ))}
          </div>
        </Content>
        <Media query={`(min-width: ${screenSizes.large.min})`}>
          {(matches) =>
            !matches ? (
              <ImageInfoWrapper>
                <ExtraInformation>
                  <Item>PRODUCT ID: {id}</Item>
                  {created_at && (
                    <Item>PUBLISHED: {formatDate(parseISO(created_at), 'dd MMM yyyy')}</Item>
                  )}
                  {glide && <Item>GLIDE: {glide}</Item>}
                  <ButtonWrapper>
                    <Button onClick={handleContactUsClick}>CONTACT US</Button>
                  </ButtonWrapper>
                  <FeedbackItem>
                    <FileText size={30} color={colors.BLUE} />
                    <FeedbackText href={urls.FEEDBACK} target="_blank" rel="noreferrer">
                      PRODUCT FEEDBACK
                    </FeedbackText>
                  </FeedbackItem>
                </ExtraInformation>
                <ImageMobile imageUrl={image_file} />
              </ImageInfoWrapper>
            ) : (
              <ExtraInformation>
                <Item>PRODUCT ID: {id}</Item>
                {created_at && (
                  <Item>PUBLISHED: {formatDate(parseISO(created_at), 'dd MMM yyyy')}</Item>
                )}
                {glide && <Item>GLIDE: {glide}</Item>}
                <ButtonWrapper>
                  <Button onClick={handleContactUsClick}>CONTACT US</Button>
                </ButtonWrapper>
                <FeedbackItem>
                  <FileText size={30} color={colors.BLUE} />
                  <FeedbackText href={urls.FEEDBACK} target="_blank" rel="noreferrer">
                    PRODUCT FEEDBACK
                  </FeedbackText>
                </FeedbackItem>
              </ExtraInformation>
            )
          }
        </Media>
      </Wrapper>
      <Wrapper>
        <EmptyDiv>
          {lat && lon && (
            <div>
              <MapContainer
                id="map-product"
                center={[lat, lon]}
                zoom={2}
                scrollWheelZoom={false}
                style={{ zIndex: 0 }}
                zoomControl={true}
                attributionControl={false}
                maxZoom={19}
              >
                <DynamicMapLayer
                  url={
                    'https://cernunosat74.cern.ch/server/rest/services/UN_Basemap_LineMapService/MapServer/'
                  }
                />
                <TiledMapLayer
                  url="https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer"
                  noWrap={true}
                  bounds={[
                    [-90, -180],
                    [90, 180],
                  ]}
                />
                <Marker position={[lat, lon]} />
              </MapContainer>
            </div>
          )}
        </EmptyDiv>
        <ExtraWrapper>
          <DownloadLinks
            title={title}
            // pdfName={pdf_name}
            pdfName={pdf_name !== 'None' ? pdf_name : undefined}
            // pdf={pdf}
            excel={excel_table.includes('/None') ? undefined : excel_table}
            // excel={excel_table}
            wmap={wmap_link}
            gdp={gdp_link}
            shp={shp_link}
            wms={wms_link}
            kml={kml_link}
            id={id}
          />
          {transformedSources && (
            <Sources>
              {transformedSources.map((item, index) => (
                <div key={`item-${index}`}>{item}</div>
              ))}
            </Sources>
          )}
        </ExtraWrapper>
      </Wrapper>
    </>
  );
};
export default ProductDetailsLarge;
