import styled from '@emotion/styled';
import ContentWrapper from '../ContentWrapper';
import { Copyright, FacebookLogo, LinkedinLogo, YoutubeLogo } from 'phosphor-react';
import MenuMobile from '../common/MenuMobile';
import { routes } from '../../constants/routes';
import { colors } from '../../constants/colors';
import { urls } from '../../constants/urls';
// import { ReactComponent as UnitarLogo } from '../../assets/logo-white-unitar.svg';
// import { ReactComponent as UnosatLogo } from '../../assets/logo-white-unosat.svg';
import { ReactComponent as TwitterLogo } from '../../assets/X_twitter.svg';
import { ReactComponent as UnitarUnosatLogo } from '../../assets/UNITAR_UNOSAT_Vertical_White_Version.svg';
import FooterDesktop from './FooterDesktop';
import Button from '../common/Button';
import { useHistory } from 'react-router';
import screenSizes from '../../constants/screenSizes';

const Wrapper = styled.div`
  background-color: ${colors.BLUE};
  color: #fff;
  justify-content: space-between;
  width: 100%;

  @media (min-width: ${screenSizes.medium.min}) {
    flex-direction: column-reverse;
  }
`;

const BottomWrapper = styled(ContentWrapper)`
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  align-items: center;
  border-top: 1px solid ${colors.GRAY_LIGHT};
`;

const CopyrightWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: #fff;
  padding-bottom: 20px;

  & div {
    display: flex;
  }

  & a {
    cursor: pointer;
    color: #fff;
    text-decoration: none;
  }
`;

const Separator = styled.span`
  margin: 0 10px;

  ::before {
    content: ' | ';
  }
`;

const Svg = styled.div`
  width: 160px;

  & svg {
    ${({ align }) => align && 'margin-top: -10px'};
  }
`;

const SvgX = styled.div`
  width: 18.75px;
  margin-top: 3px;
`;

const Logo = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
`;

const Logos = styled.div`
  display: flex;
  align-self: flex-start;
  flex: 0 0 20%;
`;

const MainSection = styled(ContentWrapper)`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${screenSizes.large.min}) {
    flex: 0 0 70%;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 30px;
  }
`;

const LogosAndButtons = styled.div`
  flex: 0 0 20%;
  display: flex;
  justify-content: space-between;

  @media (min-width: ${screenSizes.large.min}) {
    flex-direction: column;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  width: 160px;

  & button:first-of-type {
    margin-bottom: 10px;
  }
`;

const Social = styled.div`
  padding-bottom: 20px;
  width: 140px;
  display: flex;
  justify-content: space-around;
`;

const LogoWrapper = styled.div`
  margin-bottom: 3px;
  margin-right: 3px;
`;

const UnosatText = styled.span`
  margin-top: 1px;
`;

const SiteFooter = () => {
  const history = useHistory();

  const handleOnUnosatClick = () => {
    history.push(routes.ABOUT_US);
  };

  const handleOnUnitarClick = () => {
    window.open(urls.UNITAR_ABOUT_US, '_blank');
  };

  return (
    <Wrapper>
      <MainSection>
        <LogosAndButtons>
          <Logos>
            {/* <Logo>
              <Svg>
                <UnitarLogo />
              </Svg>
            </Logo>
            <Logo>
              <Svg align>
                <UnosatLogo />
              </Svg>
            </Logo> */}
            <Logo>
              <Svg>
                <UnitarUnosatLogo />
              </Svg>
            </Logo>
          </Logos>
          <Buttons>
            <Button variant="white" onClick={handleOnUnosatClick}>
              About UNOSAT
            </Button>
            <Button variant="ghost" onClick={handleOnUnitarClick}>
              About UNITAR
            </Button>
          </Buttons>
        </LogosAndButtons>

        <FooterDesktop />
        <MenuMobile />
      </MainSection>

      <BottomWrapper>
        <CopyrightWrapper>
          <div>
            <LogoWrapper>
              <Copyright />
            </LogoWrapper>
            <UnosatText>UNOSAT 2022</UnosatText>
          </div>
          <Separator />
          <a href={urls.TERMS_OF_USE} target="_blank" rel="noreferrer">
            Terms of Use
          </a>
        </CopyrightWrapper>
        <Social>
          <a href={urls.LINKEDIN} target="_blank" rel="noreferrer">
            <LinkedinLogo size={24} color="#fff" weight="fill" />
          </a>
          <a href={urls.TWITTER} target="_blank" rel="noreferrer">
            <SvgX>
              <TwitterLogo />
            </SvgX>
          </a>
          <a href={urls.FACEBOOK} target="_blank" rel="noreferrer">
            <FacebookLogo size={24} color="#fff" weight="fill" />
          </a>
          <a href={urls.YOUTUBE} target="_blank" rel="noreferrer">
            <YoutubeLogo size={24} color="#fff" weight="fill" />
          </a>
        </Social>
      </BottomWrapper>
    </Wrapper>
  );
};

export default SiteFooter;
