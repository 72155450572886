import { routes } from '../../constants/routes';
import styled from '@emotion/styled';
import screenSizes from '../../constants/screenSizes';
import { urls } from '../../constants/urls';
import { colors } from '../../constants/colors';
import { MagnifyingGlass } from 'phosphor-react';
import { XCircle } from 'phosphor-react'
import { useHistory } from 'react-router';
import { useState } from 'react';

const Nav = styled.ul`
  display: none;

  @media (min-width: ${screenSizes.large.min}) {
    display: flex;
    align-items: center;
    font-size: 16px;
  }
`;

const LinkText = styled.li`
  font-size: 15px;
  margin: 0 15px;
  list-style: none;
  cursor: pointer;
  font-weight: 700;
  ${({ hasBtnStyles }) => hasBtnStyles && 'height: 35px;'}
  ${({ hasBtnStyles }) => hasBtnStyles && 'background-color: #fff;'}
  ${({ hasBtnStyles }) => hasBtnStyles && 'padding: 10px 15px;'}
  ${({ hasBtnStyles }) => hasBtnStyles && 'border-radius: 20px;'}
  display: flex;
  align-items: center;

  & a {
    text-decoration: none;
    
    @-moz-document url-prefix() {
      ${({ hasBtnStyles }) => hasBtnStyles && 'height: 13px;'}
    }
  }

  & span {
    color: ${({ hasBtnStyles }) => (hasBtnStyles ? colors.BLUE : '#fff')};
  }

  &:hover {
    border-bottom: 1px solid #fff;
  }
`;

const ContactUsButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 37px;
  border-radius: 20px;
  cursor: pointer;
  outline: none;
  border: none;
  max-width: 220px;
  padding: 10px 15px;
  margin: 0 15px;
  background-color: #f4f6f6;
  color: ${colors.BLUE};

  & span {
    font-size: 15px;
    font-weight: 600;
  }
`

const Search = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const NavigationItems = ({ handleToggle, isSearchToggled, setIsSearchToggled }) => {
  const history = useHistory();

  const handleOnContactUsButton = () => {
    history.push(routes.CONTACT);
  }

  // switch search icon for toggle
  const handleSearchToggle = () => {
    setIsSearchToggled(!isSearchToggled);
    handleToggle();
  }

  return (
    <Nav>
      <LinkText>
        <a href={routes.PRODUCTS}>
          <span>PRODUCTS</span>
        </a>
      </LinkText>
      <LinkText>
        <a href={routes.SERVICES}>
          <span>SERVICES</span>
        </a>
      </LinkText>
      <LinkText>
        <a href={urls.UNITAR_PROJECTS} target="_blank" rel="noreferrer">
          <span>PROJECTS</span>
        </a>
      </LinkText>
      <LinkText>
        <a href={routes.PARTNERS}>
          <span>PARTNERS</span>
        </a>
      </LinkText>
      <LinkText>
        <a href={routes.ABOUT_US}>
          <span>ABOUT US</span>
        </a>
      </LinkText>
      <ContactUsButton onClick={handleOnContactUsButton}>
        <span>CONTACT US</span>
      </ContactUsButton>
      {/* <Search>
        <MagnifyingGlass size={24} color="#fff" onClick={handleToggle} />
      </Search> */}
      <Search onClick={handleSearchToggle}>
        {isSearchToggled ? (
          <XCircle size={24} color="#fff" />
        ) : (
          <MagnifyingGlass size={24} color="#fff" />
        )}
      </Search>
    </Nav>
  );
};

export default NavigationItems;
