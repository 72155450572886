import ContentWrapper from '../../components/ContentWrapper';
import styled from '@emotion/styled';
import screenSizes from '../../constants/screenSizes';
import { colors } from '../../constants/colors';
import SectionTitle from '../../components/common/SectionTitle';
import ServicePage from './ServicePage';
import ServiceCardItem from './ServiceCardItem';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router';

const Section = styled.section`
  background-color: ${colors.GRAY_LIGHT};
  width: 100%;
  z-index: 0;

  @media (min-width: ${screenSizes.medium.min}) {
    padding: 20px 0;
  }
`;

const Description = styled.p`
  color: ${colors.BLUE};
  font-size: 18px;
  margin-left: 13px;

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 18px;
  }
`;

const Wrapper = styled.div`
  padding: 20px;
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  margin: 0 auto;
  max-width: 1300px;

  @media (min-width: ${screenSizes.large.min}) {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  @media (min-width: ${screenSizes.medium.min}) {
    padding: 20px 50px;
  }
`;

const ServiceWrapper = styled.div`
  background-color: #fff;
`;

const ServicesPage = () => {
  const history = useHistory();
  const [services, setServices] = useState(null);
  const selectedService = history?.location?.state?.selectedService;

  useEffect(() => {
    axios
      .get('/our_services')
      .then((response) => {
        setServices(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const visibleServiceItem = services?.filter((service) => service.title === selectedService)[0];
  const servicesLength = services?.length;

  return (
    <>
      <Section>
        <ContentWrapper>
          <SectionTitle text="SERVICES" />
          <Description>
            UNOSAT supports UN agencies and Member States with Geospatial Analysis. Discover all our
            services:
          </Description>
        </ContentWrapper>
        <Wrapper servicesLength={servicesLength}>
          {services && (
            <>
              {services
                .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                .map(({ icon, title }, index) => (
                  <ServiceCardItem
                    key={`service-${index}`}
                    icon={icon}
                    title={title}
                    selectedService={selectedService}
                  />
                ))}
            </>
          )}
        </Wrapper>
      </Section>
      <div id='service'>
        {visibleServiceItem && (
          <ServiceWrapper>
            <ServicePage {...visibleServiceItem} />
          </ServiceWrapper>
        )}
      </div>
    </>
  );
};

export default ServicesPage;
