import styled from '@emotion/styled';
import { colors } from '../../../constants/colors';
import { routes } from '../../../constants/routes';
import screenSizes from '../../../constants/screenSizes';
import { ReactComponent as UnitarUnosatLogo } from '../../../assets/UNITAR_UNOSAT_Vertical_White_Version.svg';
import ContentWrapper from '../../ContentWrapper';
import { MagnifyingGlass, XCircle } from 'phosphor-react';
import { useToggle } from '../../../utils/toggle';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { Link as ScrollLink } from 'react-scroll';
import NavigationMenu from '../../site-header/NavigationMenu';

const HeaderWrapper = styled.div`
    position: fixed;
  width: 100%;
  z-index: 100;
`;

const Header = styled.header`
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.05));
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  justify-items: center;
  padding: 20px;
  padding-inline: 50px;
`;

const Logos = styled.div`
  display: flex;
  margin-left: -20px;
`;

const Logo = styled.a`
  flex: 0 0 auto;
  cursor: pointer;
  text-decoration: none;
`;

const Svg = styled.div`
  width: 130px;

  ${({ align }) => align && 'margin-top: -10px'};
`;

const Menu = styled.nav`
  display: flex;
  justify-content: center;
  flex: 1 1 auto;
  margin-left: -13px;

  @media (max-width: ${screenSizes.large.min}) {
    display: none;
  }
`;

const MenuItem = styled(ScrollLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 37px;
  border-radius: 20px;
  cursor: pointer;
  outline: none;
  border: none;
  max-width: 220px;
  padding-inline: 15px;
  margin: 0 15px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;

  & span {
    font-size: 15px;
    font-weight: 600;
  }

  &:hover {
    background-color: white;
    color: #1c2833;
  }
`;

const Search = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const SearchInput = styled.div`
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(226, 226, 226);
  border-radius: 3px;
  margin-right: 4px;
  width: 90%;
`;

const Input = styled.input`
  height: 78px;
  width: 100%;
  border: 0;
  outline: none;
  font-size: 16px;
  letter-spacing: 3px;
  padding: 10px 30px;
`;

const GoButton = styled.button`
  background-color: #fff;
  border: 1px solid rgb(226, 226, 226);
  border-radius: 3px;
  color: ${colors.BLUE};
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  padding: 0 20px;
  height: 80px;
  margin-right: 4px;
`;

const SearchWrapper = styled.div`
  transition: all 0.3s ease;
  box-shadow: -1px 2px 6px 0 rgba(0, 0, 0, 0.17);
  border-bottom: 4px solid ${colors.BLUE};
  background-color: rgba(6, 45, 67, 0.85);
  height: 500px;
`;

// const SearchButton = styled.button`
//   flex: 0 0 auto;
//   background: none;
//   border: none;
//   color: white;
//   cursor: pointer;
// `;

const HomeHeader = () => {

  const history = useHistory();
  const [searchValue, setSearchValue] = useState('');
  const { isOpen, handleToggle, handleClose } = useToggle(false);
  const [isSearchToggled, setIsSearchToggled] = useState(false);

  const handleOnSubmit = () => {
    history.push(routes.SEARCH + '?q=' + searchValue, { searchValue });
    handleClose();
    setIsSearchToggled(false);
  };

  const handleOnChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleOnSubmit();
    }
  };

  // switch search icon for toggle
  const handleSearchToggle = () => {
    setIsSearchToggled(!isSearchToggled);
    handleToggle();
  }

  return (
    <HeaderWrapper>
      <Header>
        <Wrapper>
          <Logos>
            <Logo href={routes.HOME}>
              <Svg>
                <UnitarUnosatLogo />
              </Svg>
            </Logo>
          </Logos>
          <NavigationMenu />
          <Menu>
            <MenuItem>PRODUCTS</MenuItem>
            <MenuItem to="servicesSection" smooth={true} duration={500}>SERVICES</MenuItem>
            <MenuItem>PROJECTS</MenuItem>
            <MenuItem>PARTNERS</MenuItem>
            <MenuItem>ABOUT US</MenuItem>
            <MenuItem>CONTACT US</MenuItem>
          </Menu>
          <Search onClick={handleSearchToggle}>
            {isSearchToggled ? (
              <XCircle size={24} color="#fff" />
            ) : (
              <MagnifyingGlass size={24} color="#fff" />
            )}
          </Search>
        </Wrapper>
      </Header>
      {isOpen && (
        <SearchWrapper>
          <Search>
            <SearchInput>
              <Input
                type="text"
                id="text-search"
                placeholder="SEARCH"
                value={searchValue}
                onChange={handleOnChange}
                onKeyDown={handleKeyDown}
              />
            </SearchInput>
            <GoButton onClick={handleOnSubmit}>GO</GoButton>
          </Search>
        </SearchWrapper>
      )}
    </HeaderWrapper>
  );
};

export default HomeHeader;
