import imageHeader from '../../assets/UNOSAT-white.png';
import ContentWrapper from '../../components/ContentWrapper';
import styled from '@emotion/styled';
import screenSizes from '../../constants/screenSizes';
import SectionTitle from '../../components/common/SectionTitle';
import Button from '../../components/common/Button';
import { urls } from '../../constants/urls';
import { colors } from '../../constants/colors';
import image from '../../assets/unosat-blue-logo.svg';
import { useEffect, useState } from 'react';
import axios from 'axios';

const Heading = styled.h1`
  color: #fff;
  font-size: 22px;
  font-weight: 900;
  z-index: 10;

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 38px;
    padding: 0 30px;
    margin-left: 40px;
  }

  @media (min-width: ${screenSizes.large.min}) {
    font-size: 42px;
  }
`;
const Header = styled.div`
  background: rgb(7, 45, 68);
  background: linear-gradient(
    257deg,
    rgba(7, 45, 68, 1) 0%,
    rgba(5, 65, 91, 1) 35%,
    rgba(4, 126, 167, 1) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-bottom: 40px;
  height: 250px;

  @media (min-width: ${screenSizes.medium.min}) {
    height: 400px;
  }
`;

const Image = styled.img`
  padding: 20px;
  width: 160px;
  height: 160px;

  @media (min-width: ${screenSizes.medium.min}) {
    width: 230px;
    height: 230px;
  }

  @media (min-width: ${screenSizes.medium.min}) {
    width: 260px;
    height: 260px;
  }
`;

const WorldImage = styled.img`
  width: 100%;
  object-fit: contain;
`;

const Wrapper = styled(ContentWrapper)`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  margin-bottom: 50px;

  @media (min-width: ${screenSizes.large.min}) {
    flex-direction: row;
    padding: 30px 50px 0;
  }
`;

const Content = styled.div`
  width: 100%;

  @media (min-width: ${screenSizes.large.min}) {
    width: 50%;
  }
`;

const Description = styled.div`
  color: ${colors.DARK_BLUE};
  margin: 20px 0;
  font-size: 18px;
  line-height: 1.4;
`;

const VideoWrapper = styled.div`
  align-self: flex-start;
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-top: 20px;

  ::after {
    display: block;
    content: '';
    padding-top: 56.25%;
  }

  @media (min-width: ${screenSizes.large.min}) {
    width: 45%;
    margin-top: 0;
  }
`;

const Video = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Title = styled.div`
  color: ${colors.DARK_BLUE};
  font-weight: 700;
  font-size: 22px;

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 22px;
  }
`;

const AboutUsPage = () => {
  const [aboutUsData, setAboutUsData] = useState(null);

  useEffect(() => {
    axios
      .get('/about')
      .then((response) => {
        setAboutUsData(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleOnUnitarClick = () => {
    window.open(urls.UNITAR_ABOUT_US, '_blank');
  };

  const description = aboutUsData?.text?.split('\n');

  return (
    <>
      <div>
        <Header image={image}>
          <Image src={imageHeader} />
          <Heading>UNITED NATIONS SATELLITE CENTRE</Heading>
        </Header>
        <ContentWrapper>
          <SectionTitle text="ABOUT US" hasLine />
        </ContentWrapper>

        <ContentWrapper>
          <Description>{description && description[0]}</Description>
        </ContentWrapper>
        <Wrapper>
          <VideoWrapper>
            <Video
              width="853"
              height="480"
              src={`https://www.youtube.com/embed/F3Xq6tnEMv0`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
          </VideoWrapper>
          <Content>
            <Title>MANDATE</Title>
            <Description>{description && description[1]}</Description>
            <Button onClick={handleOnUnitarClick}>About UNITAR</Button>
          </Content>
        </Wrapper>
        <ContentWrapper>
          <SectionTitle text="UNOSAT IN THE WORLD" hasLine />
        </ContentWrapper>
      </div>
      <WorldImage
        src={aboutUsData?.image_file}
        // onError={(e) => (e.target.src = worldImage)}
      />
    </>
  );
};

export default AboutUsPage;
