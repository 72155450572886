import styled from '@emotion/styled';
import screenSizes from '../../constants/screenSizes';
import { colors } from '../../constants/colors';
import { useHistory } from 'react-router';
import { routes } from '../../constants/routes';

const Item = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 5px #ebecec;
  align-items: center;
  padding: 20px 0;
  cursor: pointer;
  background-color: #fff;
  border-radius: 12px;
  width: 100%;
  margin-bottom: 20px;
  color: ${colors.BLUE};
  ${({ isSelected }) => isSelected && `border: 3px solid ${colors.BLUE};`};

  @media (min-width: ${screenSizes.small.min}) {
    padding: 30px 0;
    margin-bottom: 0;
  }

  @media (min-width: ${screenSizes.large.min}) {
    max-width: 290px;
  }
`;

const Image = styled.img`
  height: 70px;
  width: 70px;

  @media (min-width: ${screenSizes.small.min}) {
    height: 100px;
    width: 100px;
  }

  @media (min-width: ${screenSizes.medium.min}) {
  }
`;

const Title = styled.div`
  text-align: center;
  font-size: 14px;
  margin: 10px 5px 0;

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 16px;
  }
`;

const ServiceCardItem = ({ icon, title, selectedService }) => {
  const history = useHistory();

  const handleOnClick = () => {
    const device = window.innerWidth <= 479 ? 'mobile' : 'desktop';
    const offset = device === 'mobile' ? 300 :  500;
    history.push(routes.SERVICES, { selectedService: title });
    const y = document.getElementById('service')?.getBoundingClientRect().top + window.pageYOffset + offset;
    window.scrollTo({top: y, behavior: 'smooth'});
  };

  const isSelected = selectedService === title;

  return (
    <>
      <Item onClick={handleOnClick} isSelected={isSelected}>
        <Image src={icon} />
        <Title>{title.toUpperCase()}</Title>
      </Item>
    </>
  );
};

export default ServiceCardItem;
