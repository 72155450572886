import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import Select, { createFilter } from 'react-select';
import { MagnifyingGlass } from 'phosphor-react';
import formatDate from 'date-fns/format';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import screenSizes from '../../../constants/screenSizes';
import axios from 'axios';
import { addMonths } from 'date-fns';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useLocation } from 'react-router-dom';

const commonInputStyles = `
  height: 50px;
  padding: 0 10px;
  font-size: 14px;
  letter-spacing: 0.5px;
  font-family: 'Muli', sans-serif;
  font-weight: 500;
  line-height: 1.5;
`;

const FilterButton = styled.button`
  background-color: #111111;
  color: white;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  ${commonInputStyles}
`;

const ResetButton = styled.button`
  background-color: white;
  color: #111111;
  border-radius: 6px;
  border: 2px solid #111111;
  cursor: pointer;
  ${commonInputStyles}
`;

const Englobe = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  padding: 10px 0;
  align-items: center;
  @media (max-width: ${screenSizes.medium.min}) {
    display: none;
  }
`;

const SearchField = styled.div`
  background-color: #f4f6f6;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 95%;
  @media (min-width: ${screenSizes.medium.min}) {
    width: 160px;
  }
  ${commonInputStyles}
`;

const Input = styled.input`
  background-color: #f4f6f6;
  border: none;
  outline: none;
  width: 100%;
  ${commonInputStyles}
`;

const DateInput = styled.input`
  background-color: #f4f6f6;
  border: none;
  outline: none;
  width: 130px;
  border-radius: 5px;
  ${commonInputStyles}
`;

const CharterFilter = styled.div`
  background-color: #f4f6f6;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  ${commonInputStyles}
  padding: 0 15px;
`;

const InlineFilters = ({
  countries = [],
  activationTypes = [],
  initialFilters,
  products,
  selectedFilters,
  setSelectedFilters,
  setProducts,
  setIsLoading,
  setCurrentPage,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [isCharterActivatedYes, setIsCharterActivatedYes] = useState(false);
  const [isCharterActivatedNo, setIsCharterActivatedNo] = useState(false);
  const [everLoaded, setEverLoaded] = useState(false);
  const transformedCountriesValues = countries?.reduce((acc, country) => {
    return [...acc, { value: country, label: country }];
  }, []);

  const transformedActivationTypes = activationTypes?.reduce((acc, activationType) => {
    return [...acc, { value: activationType, label: activationType }];
  }, []);

  const handleResetFiltersOnClick = () => {
    setIsLoading(true);
    const defaultDateFrom = formatDate(addMonths(new Date(), -6), 'yyyy-MM-dd');
    const defaultDateTo = formatDate(new Date(), 'yyyy-MM-dd');

    let is_charter = isCharterActivatedYes ? true : false;
    if ((is_charter && isCharterActivatedNo) || (!is_charter && !isCharterActivatedNo)) {
      is_charter = null;
    }

    const postParams = {
      date_from: defaultDateFrom,
      date_to: defaultDateTo,
      title: "",
      is_charter: null,
      is_active: false,
    };

    const queryParams = new URLSearchParams({
      date_from: defaultDateFrom,
      date_to: defaultDateTo,
      region: '',
      activation_type: '',
      title: ('').toString(),
      is_charter: is_charter,
    }).toString();

    console.log('check reset post params : ', postParams);

    history.push(`?${queryParams}`);

    axios
      .post('/our_products/', postParams)
      .then((response) => {
        setProducts(response.data);
        console.log('check reset res : ', response.data);
        setIsLoading(false);
        setCurrentPage(0);
      })
      .catch((err) => {});
  };

  const handleApplyFiltersOnClick = () => {
    setIsLoading(true);
    const defaultDateFrom = formatDate(addMonths(new Date(), -6), 'yyyy-MM-dd');
    const defaultDateTo = formatDate(new Date(), 'yyyy-MM-dd');

    const countries = selectedFilters?.region?.reduce((acc, country) => {
      return [...acc, country.value];
    }, '');

    const activationTypes = selectedFilters?.activation_type?.reduce((acc, activationType) => {
      return [...acc, activationType.value];
    }, '');

    let is_charter = isCharterActivatedYes ? true : false;
    if ((is_charter && isCharterActivatedNo) || (!is_charter && !isCharterActivatedNo)) {
      is_charter = null;
    }

    const postParams = {
      ...selectedFilters,
      date_from: selectedFilters.date_from || defaultDateFrom,
      date_to: selectedFilters.date_to || defaultDateTo,
      region: countries || '',
      activation_type: activationTypes || '',
      title: selectedFilters?.title || '',
      is_charter: is_charter,
      is_active: selectedFilters?.is_active || false,
    };

    if (postParams.region === '') {
      delete postParams.region;
    }
    if (postParams.activation_type === '') {
      delete postParams.activation_type;
    }

    const queryParams = new URLSearchParams({
      date_from: selectedFilters.date_from,
      date_to: selectedFilters.date_to,
      region: countries ? selectedFilters.region?.map((country) => country.value).join(',') : '',
      activation_type: activationTypes
        ? selectedFilters.activation_type?.map((activationType) => activationType.value).join(',')
        : '',
      title: (selectedFilters?.title || '').toString(),
      is_charter: is_charter,
    }).toString();

    console.log('check apply post params : ', postParams);

    history.push(`?${queryParams}`);

    axios
      .post('/our_products/', postParams)
      .then((response) => {
        setProducts(response.data);
        console.log('check apply res : ', response.data);
        setIsLoading(false);
        setCurrentPage(0);
      })
      .catch((err) => {});
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleApplyFiltersOnClick();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const isValidDate = (date) => {
    return date && date !== 'null' && date !== 'undefined';
  };

  useEffect(() => {
    if (!products) {
      return;
    }

    if (everLoaded) {
      return;
    }

    setEverLoaded(true);
    const searchParams = new URLSearchParams(location.search);
    let dateFrom = searchParams.get('date_from');
    let dateTo = searchParams.get('date_to');
    let region = searchParams.get('region');
    let activationType = searchParams.get('activation_type');
    let title = searchParams.get('title');
    const isCharter = searchParams.get('is_charter');
    let regionArray = [];
    let activationTypeArray = [];
    let regionArrayForPost = [];
    let activationTypeArrayForPost = [];

    title = title === 'null' || title === 'undefined' ? '' : title;
    if (region) {
      regionArray = region.split(',').map((country) => {
        return { value: country, label: country };
      });
      regionArrayForPost = region.split(',').map((country) => {
        return country;
      });
    }

    if (activationType) {
      activationTypeArray = activationType.split(',').map((activationType) => {
        return { value: activationType, label: activationType };
      });
      activationTypeArrayForPost = activationType.split(',').map((activationType) => {
        return activationType;
      });
    }

    dateFrom = isValidDate(dateFrom)
      ? dateFrom
      : formatDate(addMonths(new Date(), -6), 'yyyy-MM-dd');

    dateTo = isValidDate(dateTo) ? dateTo : formatDate(new Date(), 'yyyy-MM-dd');
    console.log('dateFrom', dateFrom);
    console.log('dateTo', dateTo);
    setSelectedFilters({
      date_from: dateFrom,
      date_to: dateTo,
      region: regionArray,
      activation_type: activationTypeArray,
      title: title,
      is_charter: isCharter,
    });

    setIsCharterActivatedYes(isCharter === 'true');
    setIsCharterActivatedNo(isCharter === 'false');
    let charter = null;
    charter = isCharter === 'true' ? true : isCharter === 'false' ? false : null;
    const postParams = {
      date_from: dateFrom,
      date_to: dateTo,
      title: title,
      is_charter: charter,
      is_active: false,
    };

    if (activationTypeArrayForPost.length > 0) {
      postParams.activation_type = activationTypeArrayForPost;
    }
    if (regionArrayForPost.length > 0) {
      postParams.region = regionArrayForPost;
    }

    axios
      .post('/our_products/', postParams)
      .then((response) => {
        setProducts(response.data);
        setIsLoading(false);
        setCurrentPage(0);
      })
      .catch((err) => {});
  }, [products]);

  const handleCharterChangeYes = () => {
    const value = !isCharterActivatedYes;
    setIsCharterActivatedYes(value);
    setIsCharterActivatedNo(false);
  };
  const handleCharterChangeNo = () => {
    const value = !isCharterActivatedNo;
    setIsCharterActivatedNo(value);
    setIsCharterActivatedYes(false);
  };

  const handleCharterChangeAll = () => {
    if (isCharterActivatedNo && isCharterActivatedYes) {
      setIsCharterActivatedYes(false);
      setIsCharterActivatedNo(false);
      return;
    }
    setIsCharterActivatedYes(true);
    setIsCharterActivatedNo(true);
  };

  const handleOnTitleChange = (event) => {
    setSelectedFilters({
      ...selectedFilters,
      title: event.target.value,
    });
  };

  const handleCountriesChange = (values) => {
    setSelectedFilters({
      ...selectedFilters,
      region: values,
    });
  };

  const handleActivationTypesChange = (values) => {
    setSelectedFilters({
      ...selectedFilters,
      activation_type: values,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log('name', name);
    setSelectedFilters({
      ...selectedFilters,
      [name]: value,
    });
  };
  return (
    <Englobe>
      <SearchField>
        <MagnifyingGlass size={50} color="#909DA2" />
        <Input
          type="text"
          id="text-search"
          placeholder="Search title"
          onChange={handleOnTitleChange}
          value={selectedFilters?.title}
        />
      </SearchField>

      <Select
        options={transformedCountriesValues}
        isMulti
        filterOption={createFilter({ matchFrom: 'start' })}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={handleCountriesChange}
        value={selectedFilters?.region}
        placeholder="Country list"
        styles={{
          control: (styles) => ({
            ...styles,
            height: '50px',
            border: '0',
            backgroundColor: '#f4f6f6',
            width: '172px',
          }),
        }}
      />

      <DateInput
        type="date"
        placeholder="Start Date"
        name="date_from"
        value={selectedFilters?.date_from}
        onChange={handleInputChange}
      />
      <DateInput
        type="date"
        placeholder="End Date"
        name="date_to"
        value={selectedFilters?.date_to}
        onChange={handleInputChange}
      />

      <Select
        options={transformedActivationTypes}
        isMulti
        filterOption={createFilter({ matchFrom: 'start' })}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={handleActivationTypesChange}
        value={selectedFilters?.activation_type}
        placeholder="Activation Type"
        styles={{
          control: (styles) => ({
            ...styles,
            height: '50px',
            border: '0',
            backgroundColor: '#f4f6f6',
            width: '172px',
          }),
        }}
      />
      <CharterFilter>
        <span style={{ fontSize: '12px' }}>
          {' '}
          International Charter Space and Major Disasters activated
        </span>

        <label style={{ fontSize: '14px' }}>
          <input
            type="checkbox"
            name="charter-activation"
            checked={isCharterActivatedYes}
            onChange={() => handleCharterChangeYes()}
          />
          Yes
        </label>
        <label style={{ fontSize: '14px' }}>
          <input
            type="checkbox"
            name="charter-activation"
            checked={isCharterActivatedNo}
            onChange={() => handleCharterChangeNo()}
          />
          No
        </label>
        <label style={{ fontSize: '14px' }}>
          <input
            type="checkbox"
            name="charter-activation"
            checked={isCharterActivatedNo && isCharterActivatedYes}
            onChange={() => handleCharterChangeAll()}
          />
          All
        </label>
      </CharterFilter>
      <FilterButton onClick={handleApplyFiltersOnClick}>APPLY FILTERS</FilterButton>
      <ResetButton onClick={handleResetFiltersOnClick}>RESET</ResetButton>
    </Englobe>
  );
};

export default InlineFilters;
