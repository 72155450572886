import styled from '@emotion/styled';
import screenSizes from '../../constants/screenSizes';
import { urls } from '../../constants/urls';
import { colors } from '../../constants/colors';
import BackgroundImage from '../../assets/new-home-page-header.png';
import { Link as ScrollLink, Element } from 'react-scroll';

import HomeHeader from '../../components/new-design/header/HomeHeader';
import EmergencyMapping from '../home/EmergencyMapping';
import InformationalBanner from '../../components/common/InformationalBanner';
import ServicesPage from '../services/ServicesPage';
import NewsAndStories from '../home/NewsAndStories';

const MainContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
`;

const ImageSection = styled.div`
  width: 100%;
  height: 800px;
  background: url(${BackgroundImage}) no-repeat center center;
  background-size: cover;
  text-align: center;
  padding: 200px;
`;

const HomeTitle = styled.h3`
  font-size: 44px;
  font-weight: 900;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`;

const Description = styled.p`
  font-size: 20px;
  font-weight: 500;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`;

const ButtonWrap = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const ButtonUNOSAT = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 37px;
  border-radius: 20px;
  cursor: pointer;
  outline: none;
  border: none;
  max-width: 220px;
  padding: 15px;
  margin: 0 15px;
  background-color: rgba(255, 255, 255, 0.9);
  color: #1c2833;
  font-weight: 600;
  text-decoration: none;

  & span {
    font-size: 15px;
    font-weight: 600;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 1);
    color: #1c2833;
  }
`;

const ButtonUNITAR = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 37px;
  border-radius: 20px;
  cursor: pointer;
  outline: none;
  border: 1px solid white;
  max-width: 220px;
  padding: 15px;
  margin: 0 15px;
  background-color: rgba(255, 255, 255, 0);
  color: rgba(255, 255, 255, 1);
  font-weight: 600;
  text-decoration: none;

  & span {
    font-size: 15px;
    font-weight: 600;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 1);
    color: #1c2833;
  }
`;

const HomePage = () => {
  return (
    <MainContainer>
      <HomeHeader />
      <ImageSection>
        <HomeTitle>FROM IMAGERY TO IMPACT</HomeTitle>
        <Description>
          The United Nations Satellite Centre (UNOSAT) is part of the United Nations Institute for
          Training and Research (UNITAR), with a mandate to provide United Nations funds, programmes
          and specialized agencies with satellite analysis, training and capacity development, at
          their request, as well as to support Member States with satellite imagery analysis over
          their respective territories and to provide training and capacity development in the use
          of geospatial information technologies, on the basis of voluntary contributions.
        </Description>
        <ButtonWrap>
          <ButtonUNOSAT href="https://unosat.org/about-us" target="_blank" >About UNOSAT</ButtonUNOSAT>
          <ButtonUNITAR href="https://unitar.org/about" target="_blank" >About UNITAR</ButtonUNITAR>
        </ButtonWrap>
      </ImageSection>
      <InformationalBanner />
      <EmergencyMapping isButtonVisible />
      {/* <ServicesPage /> */}
      <Element name="servicesSection">
        <ServicesPage />
      </Element>
      <NewsAndStories />
    </MainContainer>
  );
};

export default HomePage;
