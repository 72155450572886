import styled from '@emotion/styled';
import screenSizes from '../constants/screenSizes';

const Wrapper = styled.div`
  ${({ bgColor }) => `background-color: ${bgColor}`};
  margin: 0 auto;
  padding: 20px;

  @media (min-width: ${screenSizes.medium.min}) {
    padding: 20px 50px;
    max-width: 1480px;
  }
`;

const ContentWrapper = ({ children, customRef, bgColor, ...rest }) => (
  <Wrapper ref={customRef} {...rest} bgColor={bgColor}>
    {children}
  </Wrapper>
);

export default ContentWrapper;
