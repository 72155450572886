import { Minus, Plus } from 'phosphor-react';
import { useEffect, useState } from 'react';
import { pages } from '../../constants/pages';
import styled from '@emotion/styled';
import screenSizes from '../../constants/screenSizes';
import { colors } from '../../constants/colors';
import axios from 'axios';
import { routes } from '../../constants/routes';
import { useHistory } from 'react-router';

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  text-decoration: none;
`;

const ItemWrapper = styled.div`
  cursor: pointer;
  color: ${({ variant }) => (variant === 'inverted' ? colors.BLUE : '#fff')};
  font-size: ${({ size }) => (size === 'large' ? '20px' : '14px')};
  padding: ${({ size }) => (size === 'large' ? '18px 0' : '8px 0')};
  width: 100%;

  a {
    text-decoration: none;
    color: ${({ variant }) => (variant === 'inverted' ? colors.BLUE : '#fff')};
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.GRAY};
  }
`;
const SubMenu = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
`;

const SubItem = styled.a`
  text-decoration: none;
  margin-bottom: 5px;
  color: ${({ variant }) => (variant === 'inverted' ? colors.BLUE : '#fff')};
`;

const Icon = styled.div`
  margin-left: 10px;
  display: ${({ isVisible }) => (isVisible ? 'block;' : 'none;')};
`;

const Item = ({ size, titleText, subItems, variant, href }) => {
  const history = useHistory();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const hasSubItems = subItems?.length > 0;
  const toggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <ItemWrapper variant={variant} size={size}>
      <Title>
        <a href={href} target={titleText === 'PROJECTS' ? '_blank' : '_self'} rel="noreferrer">{titleText}</a>
        <Icon isVisible={hasSubItems} onClick={toggle}>
          {isMenuOpen ? <Minus size={12} onClick={toggle} /> : <Plus size={12} onClick={toggle} />}
        </Icon>
      </Title>
      {hasSubItems && isMenuOpen && (
        <SubMenu>
          {subItems.map(({ title, href }, index) => (
            <SubItem
              onClick={() => history.push(href, { selectedService: title })}
              key={`subItem-${index}`}
              variant={variant}
            >
              {title}
            </SubItem>
          ))}
        </SubMenu>
      )}
    </ItemWrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;

  @media (min-width: ${screenSizes.large.min}) {
    display: none;
  }
`;

const MenuMobile = ({ variant = 'default', size = 'small' }) => {
  const [services, setServices] = useState(null);

  useEffect(() => {
    axios
      .get('/our_services')
      .then((response) => {
        setServices(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const transformServices = services
    ?.sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
    .map((service) => {
      return {
        title: service.title,
        href: routes.SERVICES,
        section: service.title,
      };
    });

  return (
    <>
      {services && (
        <Wrapper>
          {Array.from(pages(transformServices)).map(({ title, items, href }) => (
            <Item
              size={size}
              variant={variant}
              key={`item-${title}`}
              titleText={title}
              subItems={items}
              href={href}
            />
          ))}
        </Wrapper>
      )}
    </>
  );
};

export default MenuMobile;
