import { ReactComponent as UnosatLogo } from '../../assets/logo-white-unosat.svg';
import { ReactComponent as UnitarLogo } from '../../assets/logo-white-unitar.svg';
import { ReactComponent as UnitarUnosatLogo } from '../../assets/UNITAR_UNOSAT_Vertical_White_Version.svg';
import styled from '@emotion/styled';
import NavigationMenu from './NavigationMenu';
import NavigationItems from './NavigationItems';
import { routes } from '../../constants/routes';
import { colors } from '../../constants/colors';
import ContentWrapper from '../ContentWrapper';
import screenSizes from '../../constants/screenSizes';
import { useToggle } from '../../utils/toggle';
import { useState } from 'react';
import { useHistory } from 'react-router';

const Header = styled.header`
  background-color: ${colors.BLUE};
`;

const Wrapper = styled(ContentWrapper)`
  min-height: 95px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 20px;

  @media (min-width: ${screenSizes.medium.min}) {
    padding: 0 50px;
  }
`;

const Svg = styled.div`
  width: 130px;

  ${({ align }) => align && 'margin-top: -10px'};
`;

const Logo = styled.a`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
`;

const Logos = styled.div`
  display: flex;
  margin-left: -13px;
`;

const SearchInput = styled.div`
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(226, 226, 226);
  border-radius: 3px;
  margin-right: 4px;
  width: 90%;
`;

const Input = styled.input`
  height: 78px;
  width: 100%;
  border: 0;
  outline: none;
  font-size: 16px;
  letter-spacing: 3px;
  padding: 10px 30px;
`;

const GoButton = styled.button`
  background-color: #fff;
  border: 1px solid rgb(226, 226, 226);
  border-radius: 3px;
  color: ${colors.BLUE};
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  padding: 0 20px;
  height: 80px;
  margin-right: 4px;
`;

const SearchWrapper = styled.div`
  transition: all 0.3s ease;
  box-shadow: -1px 2px 6px 0 rgba(0, 0, 0, 0.17);
  border-bottom: 4px solid ${colors.BLUE};
  background-color: rgba(6, 45, 67, 0.85);
  height: 500px;
`;

const Search = styled.div`
  padding: 50px;
  display: flex;
  margin: 0 auto;
  width: 80%;
`;

const HeaderWrapper = styled.div`
  position: fixed;
  width: 100%;
  z-index: 100;
`;

const SiteHeader = () => {
  const history = useHistory();
  const [searchValue, setSearchValue] = useState('');
  const { isOpen, handleToggle, handleClose } = useToggle(false);
  const [isSearchToggled, setIsSearchToggled] = useState(false);

  const handleOnSubmit = () => {
    history.push(routes.SEARCH + '?q=' + searchValue, { searchValue });
    handleClose();
    setIsSearchToggled(false);
  };

  const handleOnChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleOnSubmit();
    }
  };

  return (
    <HeaderWrapper>
      <Header>
        <Wrapper>
          <Logos>
            {/* <Logo href={routes.HOME}>
              <Svg>
                <UnitarLogo />
              </Svg>
            </Logo>
            <Logo href={routes.HOME}>
              <Svg align>
                <UnosatLogo />
              </Svg>
            </Logo> */}
            <Logo href={routes.HOME}>
              <Svg>
                <UnitarUnosatLogo />
              </Svg>
            </Logo>
          </Logos>
          <NavigationMenu />
          <NavigationItems
            handleToggle={handleToggle}
            isSearchToggled={isSearchToggled}
            setIsSearchToggled={setIsSearchToggled}
          />
        </Wrapper>
      </Header>
      {isOpen && (
        <SearchWrapper>
          <Search>
            <SearchInput>
              <Input
                type="text"
                id="text-search"
                placeholder="SEARCH"
                value={searchValue}
                onChange={handleOnChange}
                onKeyDown={handleKeyDown}
              />
            </SearchInput>
            <GoButton onClick={handleOnSubmit}>GO</GoButton>
          </Search>
        </SearchWrapper>
      )}
    </HeaderWrapper>
  );
};

export default SiteHeader;
