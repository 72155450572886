import { DownloadSimple } from 'phosphor-react';
import { colors } from '../../../constants/colors';
import styled from '@emotion/styled';
import { useEffect } from 'react';

const DownloadItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  border-left: 2px solid ${colors.BLUE};
  margin-bottom: 20px;
  padding-left: 15px;
`;

const DownloadItem = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 5px 0;
`;

const DownloadLink = styled.a`
  color: ${colors.BLUE};
  text-decoration: none;
  font-weight: 700;
  margin: 0 8px;
`;

const DownloadDescription = styled.span`
  font-size: 16px;
`;

const DownloadLinks = ({ title, pdfName, excel, wmap, gdp, shp, kml, wms, id }) => {
  return (
    <DownloadItemsWrapper>
      {pdfName && (
        <DownloadItem>
          <DownloadSimple size={28} color={colors.BLUE} />
          <DownloadLink
            href={`/static/unosat_filesystem/${id}/${pdfName}`}
            download={pdfName}
            target="_blank"
          >
            PDF
          </DownloadLink>
          <DownloadDescription> - static viewing and printing </DownloadDescription>
          {/* <object
            data={`/static/unosat_filesystem/${id}/${pdfName}`}
            type="application/pdf"
            preload="auto"
            cache="true"
          > */}
            <DownloadLink
              href={`/static/unosat_filesystem/${id}/${pdfName}`}
              target="_blank"
              style={{ textDecoration: 'underline' }}
            >
              Click to view PDF
            </DownloadLink>
          {/* </object> */}
        </DownloadItem>
      )}
      {shp && (
        <DownloadItem>
          <DownloadSimple size={28} color={colors.BLUE} />
          <DownloadLink href={shp} download={shp}>
            SHAPEFILE
          </DownloadLink>
          <DownloadDescription> - download a Shapefile of data</DownloadDescription>
        </DownloadItem>
      )}
      {gdp && (
        <DownloadItem>
          <DownloadSimple size={28} color={colors.BLUE} />
          <DownloadLink href={gdp} download={gdp}>
            GEODATABASE
          </DownloadLink>
          <DownloadDescription> - download data in the ESRI format</DownloadDescription>
        </DownloadItem>
      )}
      {excel && (
        <DownloadItem>
          <DownloadSimple size={28} color={colors.BLUE} />
          <DownloadLink href={`/static/${excel}`} download={title}>
            TABLE
          </DownloadLink>
          <DownloadDescription> - Excel</DownloadDescription>
        </DownloadItem>
      )}
      {wmap && (
        <DownloadItem>
          <DownloadSimple size={28} color={colors.BLUE} />
          <DownloadLink href={wmap} target="_blank">
            WEBMAP
          </DownloadLink>
          <DownloadDescription> - Dynamic viewing in a browser</DownloadDescription>
        </DownloadItem>
      )}
      {wms && (
        <DownloadItem>
          <DownloadSimple size={28} color={colors.BLUE} />
          <DownloadLink href={wms}>WMS</DownloadLink>
          <DownloadDescription> - Web Map Server</DownloadDescription>
        </DownloadItem>
      )}
      {kml && (
        <DownloadItem>
          <DownloadSimple size={28} color={colors.BLUE} />
          <DownloadLink href={kml} download={kml}>
            KML
          </DownloadLink>
        </DownloadItem>
      )}
    </DownloadItemsWrapper>
  );
};

export default DownloadLinks;
