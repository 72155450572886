import { useState } from 'react';
import styled from '@emotion/styled';
import { Check } from 'phosphor-react';

const CheckboxContainer = styled.div`
  background-color: #f4f6f6;
  height: 25px;
  position: relative;
  width: 25px;
`;

const CheckboxIcon = styled.div`
  align-items: center;
  border-radius: 2px;
  box-sizing: border-box;
  display: flex;
  height: 25px;
  justify-content: center;
  width: 25px;

  background-color: ${({ isCheckboxChecked }) => (isCheckboxChecked ? '#4b93dd;' : 'transparent')};
  border: ${({ isCheckboxChecked }) =>
    isCheckboxChecked ? '1px solid #4b93dd' : '1px solid #f4f6f6'};
`;

const Input = styled.input`
  box-sizing: border-box;
  cursor: pointer;
  height: 25px;
  left: 0;
  margin: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 25px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;

  &:not(:first-child) {
    margin-left: 10px;
  }
`;

const Label = styled.span`
  text-align: center;
  margin-left: 8px;
`;

const Checkboxes = styled.div`
  display: flex;
`;

const Checkbox = ({ id, name, setSelectedFilters, selectedFilters }) => {
  const [isYesCheckboxChecked, setIsYesCheckboxChecked] = useState(selectedFilters?.is_charter);
  const [isNoCheckboxChecked, setIsNoCheckboxChecked] = useState(selectedFilters?.is_charter === false && selectedFilters?.is_charter !== null);
  const [isAllCheckboxChecked, setIsAllCheckboxChecked] = useState(selectedFilters?.is_charter === null);

  const handleOnYesChange = (event) => {
    const { target } = event;
    setIsYesCheckboxChecked(target.checked);
    setIsNoCheckboxChecked(!target.checked);
    setIsAllCheckboxChecked(false)
    setSelectedFilters({
      ...selectedFilters,
      is_charter: true,
    });
  };

  const handleOnNoChange = (event) => {
    const { target } = event;
    setIsNoCheckboxChecked(target.checked);
    setIsYesCheckboxChecked(!target.checked);
    setIsAllCheckboxChecked(false)
    setSelectedFilters({
      ...selectedFilters,
      is_charter: false,
    });
  };

  const handleOnAllChange = (event) => {
    const { target } = event;
    setIsNoCheckboxChecked(!target.checked);
    setIsYesCheckboxChecked(!target.checked);
    setIsAllCheckboxChecked(true)
    setSelectedFilters({
      ...selectedFilters,
      is_charter: null,
    });
  };

  return (
    <Checkboxes>
      <Wrapper>
        <CheckboxContainer>
          <Input
            id={id}
            name={name}
            type="checkbox"
            checked={isYesCheckboxChecked}
            onChange={handleOnYesChange}
            value={isYesCheckboxChecked}
          />

          <CheckboxIcon isCheckboxChecked={isYesCheckboxChecked}>
            {isYesCheckboxChecked && <Check size={20} color="#fff" />}
          </CheckboxIcon>
        </CheckboxContainer>
        <Label>Yes</Label>
      </Wrapper>
      <Wrapper>
        <CheckboxContainer>
          <Input
            id={id}
            name={name}
            type="checkbox"
            checked={isNoCheckboxChecked}
            onChange={handleOnNoChange}
            value={isNoCheckboxChecked}
          />

          <CheckboxIcon isCheckboxChecked={isNoCheckboxChecked}>
            {isNoCheckboxChecked && <Check size={20} color="#fff" />}
          </CheckboxIcon>
        </CheckboxContainer>
        <Label>No</Label>
      </Wrapper>
      <Wrapper>
        <CheckboxContainer>
          <Input
            id={id}
            name={name}
            type="checkbox"
            checked={isAllCheckboxChecked}
            onChange={handleOnAllChange}
            value={isAllCheckboxChecked}
          />

          <CheckboxIcon isCheckboxChecked={isAllCheckboxChecked}>
            {isAllCheckboxChecked && <Check size={20} color="#fff" />}
          </CheckboxIcon>
        </CheckboxContainer>
        <Label>All</Label>
      </Wrapper>
    </Checkboxes>
  );
};

export default Checkbox;
