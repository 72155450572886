import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { colors } from '../../constants/colors';
import screenSizes from '../../constants/screenSizes';

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-radius: ${({ type }) => (type === 'round' ? '20px' : '0')};
  cursor: pointer;
  outline: none;
  border: none;
  max-width: 220px;
  padding: ${({ size }) => (size === 'standard' ? '10px 20px' : '10px 40px')};

  & span {
    font-size: 16px;
    font-weight: 600;
  }

  ${({ variant }) => variantStyles(variant)};

  @media (min-width: ${screenSizes.medium.min}) {
    & span {
      font-size: 16px;
    }
  }
`;

const variantStyles = (variant) => {
  switch (variant) {
    case 'white':
      return whiteButtonStyles;

    case 'ghost':
      return ghostStyles;

    case 'dark':
      return darkStyles;

    default:
      return defaultStyles;
  }
};

const defaultStyles = css`
  background-color: ${colors.BLUE};
  color: #fff;

  &:hover {
    background-color: #fff;
    color: ${colors.BLUE};
    border: 1px solid ${colors.BLUE};
  }
`;

const darkStyles = css`
  background-color: ${colors.DARK_BLUE};
  color: #fff;

  &:hover {
    background-color: #fff;
    color: ${colors.DARK_BLUE};
    border: 1px solid ${colors.DARK_BLUE};
  }
`;

const whiteButtonStyles = css`
  background-color: #f4f6f6;
  color: ${colors.BLUE};
`;

const ghostStyles = css`
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
`;

const Button = ({ children, size = 'standard', type = 'round', variant, onClick }) => (
  <StyledButton variant={variant} type={type} size={size} onClick={onClick}>
    {children && <span>{children}</span>}
  </StyledButton>
);

export default Button;
