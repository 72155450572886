import screenSizes from '../../../constants/screenSizes';
import styled from '@emotion/styled';
import { colors } from '../../../constants/colors';

const Label = styled.label`
  display: inline-block;
  height: 30px;
  position: relative;
  width: 48px;
  margin-right: 10px;

  @media (min-width: ${screenSizes.large.min}) {
    height: 22px;
    width: 35px;
  }
`;

const Slider = styled.span`
  input:checked ~ & {
    background-color: ${({ color }) => color};
  }

  input:focus ~ & {
    box-shadow: 0 0 4px ${colors.GRAY};
  }

  input:checked ~ &::before {
    transform: translateX(18px);

    @media (min-width: ${screenSizes.large.min}) {
      transform: translateX(13px);
    }
  }

  background-color: ${colors.GRAY};
  border-radius: 18px;
  bottom: 0;
  box-shadow: ${({ hasError }) => hasError && `0 0 4px ${colors.BLUE}`};
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;

  &::before {
    background-color: #fff;
    border-radius: 50%;
    bottom: 2px;
    content: '';
    height: 26px;
    left: 2px;
    position: absolute;
    transition: 0.4s;
    width: 26px;

    @media (min-width: ${screenSizes.large.min}) {
      height: 18px;
      width: 18px;
    }
  }
`;

const Input = styled('input')`
  height: 0;
  opacity: 0;
  width: 0;
`;

const SwitchButton = ({
  color = colors.BLUE,
  id,
  hasError,
  setSelectedFilters,
  selectedFilters,
  ...props
}) => {
  const handleChange = (e) => {
    setSelectedFilters({
      ...selectedFilters,
      is_active: !e.target.checked,
    });
  };

  return (
    <Label hasError={hasError} htmlFor={id}>
      <Input
        {...props}
        checked={!selectedFilters?.is_active || false}
        color={color}
        id={id}
        name={id}
        onChange={handleChange}
        type="checkbox"
        value={!selectedFilters?.is_active || false}
      />
      <Slider color={color} hasError={hasError} />
    </Label>
  );
};

export default SwitchButton;
