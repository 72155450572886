import SectionTitle from '../../../components/common/SectionTitle';
import ContentWrapper from '../../../components/ContentWrapper';
import styled from '@emotion/styled';
import { colors } from '../../../constants/colors';
import applicationImage from '../../../assets/platforms-min.png';
import screenSizes from '../../../constants/screenSizes';
import ApplicationItem from './ApplicationItem';
import { useEffect, useState } from 'react';
import axios from 'axios';

const categories = {
  UNOSAT: 'Apps and Platforms',
  OTHER: 'Other Distribution Channels',
};

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${screenSizes.large.min}) {
    flex-direction: row;
  }
`;

const Section = styled.section`
  padding-bottom: 80px;
`;

const Description = styled.p`
  color: ${colors.BLUE};
  margin-top: 10px;
  font-size: 18px;
  line-height: 1.2;
  margin-left: 13px;
}
`;

const Image = styled.img`
  max-width: 310px;

  @media (min-width: ${screenSizes.medium.min}) {
    max-width: 480px;
  }
`;

const ItemsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  @media (min-width: 662px) {
    justify-content: flex-start;
    max-width: 720px;
    margin: 0 auto;
  }

  @media (min-width: ${screenSizes.large.min}) {
    justify-content: flex-start;
    max-width: 1050px;
    margin: 0 auto;
  }
`;

const ApplicationsSection = () => {
  const [applications, setApplications] = useState(null);

  useEffect(() => {
    axios
      .get('/our_platforms')
      .then((response) => {
        setApplications(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Section id="platforms">
      <ContentWrapper>
        <Header>
          <div>
            <SectionTitle text="PLATFORMS & APPLICATIONS" />
            <Description>
              UNOSAT developed, in close collaboration with partners, different applications and
              platforms to provide more information and disseminate products further.{' '}
            </Description>
          </div>
          <Image src={applicationImage} />
        </Header>
      </ContentWrapper>
      {applications && (
        <>
          <ContentWrapper>
            <ContentWrapper>
              <SectionTitle
                text="UNOSAT PLATFORMS & APPLICATIONS"
                isUnderlined
                hasLeftLine={false}
              />
            </ContentWrapper>
            <ItemsWrapper>
              {applications
                .filter((app) => app.category === categories.UNOSAT)
                .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                .map(({ platform_name, icon, link }, index) => (
                  <ApplicationItem
                    key={`application-unosat-${index}`}
                    platform_name={platform_name}
                    icon={icon}
                    link={link}
                    isGDACS={platform_name === 'GDACS '}
                  />
                ))}
            </ItemsWrapper>
          </ContentWrapper>
          <ContentWrapper>
            <ContentWrapper>
              <SectionTitle text="OTHER DISTRIBUTION CHANNELS" isUnderlined hasLeftLine={false} />
            </ContentWrapper>
            <ItemsWrapper>
              {applications
                .filter((app) => app.category === categories.OTHER)
                .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                .map(({ platform_name, icon, link }, index) => (
                  <ApplicationItem
                    key={`application-other-${index}`}
                    platform_name={platform_name}
                    icon={icon}
                    link={link}
                  />
                ))}
            </ItemsWrapper>
          </ContentWrapper>
        </>
      )}
    </Section>
  );
};

export default ApplicationsSection;
