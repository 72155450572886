import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { colors } from '../../constants/colors';
import { useEffect, useState } from 'react';
import axios from 'axios';
import screenSizes from '../../constants/screenSizes';

// const BannerWrapper = styled.div`
//   position: relative;
//   height: 60px;
//   background-color: ${({ color }) => color};
//   width: 100%;
//   overflow: hidden;
// `;

const BannerWrapper = styled.div`
  position: relative;
  height: 60px;
  background-color: #DB3E3E;
  width: 100%;
  overflow: hidden;
`;

const animate = keyframes`
  0% {
    margin-left: 100%;
  }
  100% {
    margin-left: -200%;
  }
`;

const TextWrapper = styled.div`
  position: absolute;
  top: 19px;
  color: #fff;
  font-weight: 700;
  font-size: 19px;
  text-transform: uppercase;
  animation: ${animate} 30s -30s linear infinite;
  white-space: nowrap;
  letter-spacing: 1px;

  @media (min-width: ${screenSizes.medium.min}) {
    animation: ${animate} 60s -60s linear infinite;
  }
`;

const Item = styled.span`
  margin-right: 170px;
`

const InformationalBanner = ({ color = colors.BLUE }) => {
  const [activations, setActivations] = useState(null);

  useEffect(() => {
    axios
      .get('/activations_banner/')
      .then((response) => {
        setActivations(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const texts = activations?.map((activation) => Object.values(activation).join(' - '));

  return (
    <BannerWrapper color={color}>
      <TextWrapper>
        {texts?.map((text, index) =>
          <Item key={`item-${index}`}>{text}</Item>
        )}
      </TextWrapper>
    </BannerWrapper>
  );
};

export default InformationalBanner;
