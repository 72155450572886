import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { CaretLeft, CaretRight, Sliders } from 'phosphor-react';
import styled from '@emotion/styled';
import ContentWrapper from '../../components/ContentWrapper';
import { colors } from '../../constants/colors';
import InformationalBanner from '../../components/common/InformationalBanner';
import SectionTitle from '../../components/common/SectionTitle';
import { useToggle } from '../../utils/toggle';
import MapResults from './MapResults';
import ApplicationsSection from './applications/ApplicationsSection';
import ReactPaginate from 'react-paginate';
import MapCardItem from './MapCardItem';
import formatDate from 'date-fns/format';
import { addMonths } from 'date-fns';
import LoadingDots from '../../components/common/LoadingDots';
import FiltersContainer from './filters/FiltersContainer';
import InlineFilters from './filters/InlineFilters';
import screenSizes from '../../constants/screenSizes';
import uniqBy from 'lodash/uniqBy';

const testObj = {
  products: [
    {
      map_event: {
        id: 3681,
        title: 'Power Supply Assessment in Derna & Al Bayda (Libya) using Night-time Light Imagery',
        image_file:
          '/static/unosat_filesystem/3681/Power_Supply_Assessment_in_Derna_%26_Al_Bayda_(Libya)_using_Night-time_Light_Imagery.jpg',
        glide: 'FL20230912LBY',
        created_at: '2023-09-27T15:17:52.322675Z',
      },
      longitude: 22.1892505,
      latitude: 32.7442775,
      area_event_name: 'Libya',
      activation_type: 'Floods',
    },
    {
      map_event: {
        id: 3680,
        title: 'Power Supply Assessment in Benghazi (Libya) using Night-time Light Imagery',
        image_file:
          '/static/unosat_filesystem/3680/Power_Supply_Assessment_in_Benghazi_(Libya)_using_Night-time_Light_Imagery.jpg',
        glide: 'FL20230912LBY',
        created_at: '2023-09-27T15:15:49.841862Z',
      },
      longitude: 20.111902,
      latitude: 32.1419885,
      area_event_name: 'Libya',
      activation_type: 'Floods',
    },
    {
      map_event: {
        id: 3679,
        title:
          'Preliminary satellite-derived flood assessment, Gambella city, Gambela Region, Ethiopia',
        image_file:
          '/static/unosat_filesystem/3679/UNOSAT_Preliminary_Assessment_Report_FL20230921ETH_Gambella_25092023_1.jpg',
        glide: 'FL20230921ETH',
        created_at: '2023-09-26T03:44:56.086927Z',
      },
      longitude: 34.58361969916965,
      latitude: 8.244979346412116,
      area_event_name: 'Ethiopia',
      activation_type: 'Floods',
    },
    {
      map_event: {
        id: 3678,
        title:
          'Night-Time Light Loss Assessment in Derna & Al Bayda (Libya) using Night-time Light Imagery',
        image_file:
          '/static/unosat_filesystem/3678/UNOSAT_CBAS_Light_Loss_Assessment_of_the_Libya_Flooding_Using_Nighttime_Light_Imagery.jpg',
        glide: 'FL20230912LBY',
        created_at: '2023-09-20T17:32:50.489733Z',
      },
      longitude: 22.1869595,
      latitude: 32.7806435,
      area_event_name: 'Libya',
      activation_type: 'Floods',
    },
    {
      map_event: {
        id: 3677,
        title: 'Flood impact assessment in Derna dams, East Province, Libya',
        image_file:
          '/static/unosat_filesystem/3677/UNOSAT_A3_Natural_Portrait_FL20230912LBY_Derna_Dams_16Sep2023.jpg',
        glide: 'FL20230912LBY',
        created_at: '2023-09-20T13:20:01.888113Z',
      },
      longitude: 22.5824755,
      latitude: 32.6958055,
      area_event_name: 'Libya',
      activation_type: 'Floods',
    },
    {
      map_event: {
        id: 3676,
        title:
          'Flood impact assessment in Dams over Benghazi and Almarj Governorates, East Province, Libya',
        image_file:
          '/static/unosat_filesystem/3676/UNOSAT_A3_Natural_Portrait_FL20230912LBY_Benghazi_Almarj_Dams_16Sep2023.jpg',
        glide: 'FL20230912LBY',
        created_at: '2023-09-20T13:18:07.299030Z',
      },
      longitude: 20.4841765,
      latitude: 32.196783499999995,
      area_event_name: 'Libya',
      activation_type: 'Floods',
    },
    {
      map_event: {
        id: 3675,
        title:
          'Preliminary satellite-derived reservoir assessment, Benghazi, Almarj & Derna governorates, East province, Libya - 19 September 2023',
        image_file:
          '/static/unosat_filesystem/3675/UNOSAT_Preliminary_Assessment_Report_FL20230912LBY_DamAssessment_19Sep2023.jpg',
        glide: 'FL20230912LBY',
        created_at: '2023-09-19T15:56:58.627946Z',
      },
      longitude: 20.60314,
      latitude: 32.2804925,
      area_event_name: 'Libya',
      activation_type: 'Floods',
    },
    {
      map_event: {
        id: 3674,
        title:
          'Power Supply Assessment Following the Adassil/Al Haouz Earthquake (8 September 2023, M6.8) using High-resolution Night-time Light Images​ - 18 September 2023',
        image_file:
          '/static/unosat_filesystem/3674/UNOSAT_Preliminary_Assessment_Report_EQ20230909MAR_NightTimeLossAssessment_SoussMassa_MarrakechSafi_Regions_18Sep2023.jpg',
        glide: 'EQ20230909MAR',
        created_at: '2023-09-18T14:08:37.606221Z',
      },
      longitude: -7.8294425,
      latitude: 31.5714145,
      area_event_name: 'Morocco',
      activation_type: 'Earthquake',
    },
    {
      map_event: {
        id: 3673,
        title: 'Flood impact assessment in Al Tamimi Town, Derna Governorate, East Province, Libya',
        image_file:
          '/static/unosat_filesystem/3673/UNOSAT_A3_Natural_Portrait_FL20230912LBY_AlTamimiTown_13Sep2023.jpg',
        glide: 'FL20230912LBY',
        created_at: '2023-09-15T16:08:05.256252Z',
      },
      longitude: 23.065993499999998,
      latitude: 32.34152,
      area_event_name: 'Libya',
      activation_type: 'Floods',
    },
    {
      map_event: {
        id: 3672,
        title: 'Flood impact assessment in Derna City centre, East Province, Libya',
        image_file:
          '/static/unosat_filesystem/3672/UNOSAT_A3_Natural_Portrait_FL20230912LBY_DernaCity_ZoomInRiver_13Sep2023.jpg',
        glide: 'FL20230912LBY',
        created_at: '2023-09-14T10:57:30.844584Z',
      },
      longitude: 22.6460985,
      latitude: 32.7671665,
      area_event_name: 'Libya',
      activation_type: 'Floods',
    },
    {
      map_event: {
        id: 3671,
        title: 'Flood impact assessment in Derna City, East Province, Libya',
        image_file:
          '/static/unosat_filesystem/3671/UNOSAT_A3_Natural_Portrait_FL20230912LBY_DernaCity_13Sep2023.jpg',
        glide: 'FL20230912LBY',
        created_at: '2023-09-13T15:48:14.711489Z',
      },
      longitude: 22.6380335,
      latitude: 32.7611775,
      area_event_name: 'Libya',
      activation_type: 'Floods',
    },
    {
      map_event: {
        id: 3670,
        title: 'Storm Daniel in Libya, September 2023 - UNOSAT Live Web Map',
        image_file: '/static/unosat_filesystem/3670/webmap_libya.JPG',
        glide: 'FL20230912LBY',
        created_at: '2023-09-12T14:32:17.128863Z',
      },
      longitude: 21.3616775,
      latitude: 32.195047,
      area_event_name: 'Libya',
      activation_type: 'Floods',
    },
    {
      map_event: {
        id: 3669,
        title:
          'Preliminary Night-time Light Loss Assessment Following the Adassil/Al Haouz Earthquake (8 September 2023, M6.8) in Marrakech-Safi Region',
        image_file:
          '/static/unosat_filesystem/3669/UNOSAT_Preliminary_Assessment_Report_EQ20230909MAR_NightTimeLossAssessment_Tameslohte_Amizmiz_12Sep2023.jpg',
        glide: 'EQ20230909MAR',
        created_at: '2023-09-12T10:32:04.515222Z',
      },
      longitude: -8.14215,
      latitude: 31.6259265,
      area_event_name: 'Morocco',
      activation_type: 'Earthquake',
    },
    {
      map_event: {
        id: 3668,
        title:
          'Damage Assessment in Chichaoua, Marrakech-Safi Region, Morocco as of 10 September 2023',
        image_file:
          '/static/unosat_filesystem/3668/UNOSAT_A3_Natural_Portrait_EQ20230909MAR_Chichaoua_12Sep2023_v2_1.jpg',
        glide: 'EQ20230909MAR',
        created_at: '2023-09-12T09:52:51.109013Z',
      },
      longitude: -8.786152942060653,
      latitude: 31.531746358439495,
      area_event_name: 'Morocco',
      activation_type: 'Earthquake',
    },
    {
      map_event: {
        id: 3667,
        title:
          'Évaluation des dégats à Taroudant, région de  Taroudant, province de Souss Massa, Maroc, au 10 Septembre 2023',
        image_file:
          '/static/unosat_filesystem/3667/UNOSAT_A3_Natural_Landscape_EQ20230909MAR_Taroudant_11Sep2023.jpg',
        glide: 'EQ20230909MAR',
        created_at: '2023-09-11T15:59:23.929162Z',
      },
      longitude: -8.8950615,
      latitude: 30.468879,
      area_event_name: 'Morocco',
      activation_type: 'Earthquake',
    },
    {
      map_event: {
        id: 3666,
        title:
          'Évaluation des dégats à Adassil, région de  Chichaoua, province de Marrakech-Safi, Maroc, au 10 Septembre 2023',
        image_file:
          '/static/unosat_filesystem/3666/UNOSAT_A3_Natural_Landscape_EQ20230909MAR_Adassil_11Sep2023.jpg',
        glide: 'EQ20230909MAR',
        created_at: '2023-09-11T13:47:23.660654Z',
      },
      longitude: -8.4944565,
      latitude: 31.1142185,
      area_event_name: 'Morocco',
      activation_type: 'Earthquake',
    },
    {
      map_event: {
        id: 3665,
        title:
          'Damage Assessment in Douar Tagadirte (Igermane), Chichaoua Province, Marrakech-Safi Region, Morocco as of 10 September 2023',
        image_file:
          '/static/unosat_filesystem/3665/UNOSAT_A3_Natural_Landscape_EQ20230909MAR_DouarTagadirte_11Sep2023_2.jpg',
        glide: 'EQ20230909MAR',
        created_at: '2023-09-11T12:31:04.970485Z',
      },
      longitude: -8.432203077264212,
      latitude: 31.143333802503285,
      area_event_name: 'Morocco',
      activation_type: 'Earthquake',
    },
    {
      map_event: {
        id: 3664,
        title:
          'Preliminary satellite-derived damage assessment, Adassil/Al Haouz, 6.8M earthquake of 08-09-2023 (22:11 UTC) Chichaoua Province, Marrakech-Safi Region, Morocco',
        image_file:
          '/static/unosat_filesystem/3664/UNOSAT_Preliminary_Assessment_Report_EQ20230909MAR_Adassil_10Sep2023.jpg',
        glide: 'EQ20230909MAR',
        created_at: '2023-09-10T14:39:16.081379Z',
      },
      longitude: -8.4836975,
      latitude: 31.121506,
      area_event_name: 'Morocco',
      activation_type: 'Earthquake',
    },
    {
      map_event: {
        id: 3663,
        title: 'M6.8 Adassil earthquake (SW Marrakesh)  on 9 September 2023',
        image_file: '/static/unosat_filesystem/3663/WebMap_Morocco.jpg',
        glide: 'EQ20230909MAR',
        created_at: '2023-09-09T14:15:40.340740Z',
      },
      longitude: -8.797794023686508,
      latitude: 31.767994075364964,
      area_event_name: 'Morocco',
      activation_type: 'Earthquake',
    },
    {
      map_event: {
        id: 3662,
        title:
          'Power Supply Assessment in Tigray, Ethiopia using Night-time Light Imagery, Tigray Region, Ethiopia',
        image_file:
          '/static/unosat_filesystem/3662/UNOSAT-RM_Power_Supply_Assessment_in_Tigray.jpg',
        glide: 'CE20230620ETH',
        created_at: '2023-09-06T11:18:34.981262Z',
      },
      longitude: 40.8471865,
      latitude: 8.740872,
      area_event_name: 'Ethiopia',
      activation_type: 'Complex Emergency',
    },
    {
      map_event: {
        id: 3660,
        title:
          "Assessment of Flooded Buildings in Bandarban, Cox's Bazaar, Chattogram, and Rangamati Districts, Chattogram Division, Bangladesh as of  12 to 22 August 2023",
        image_file:
          '/static/unosat_filesystem/3660/UNOSAT_A3_Natural_Protrait_FL20230807BGD_24Aug2023_1.png',
        glide: 'FL20230807BGD',
        created_at: '2023-08-24T12:00:34.678311Z',
      },
      longitude: 92.093583,
      latitude: 22.1881165,
      area_event_name: 'Bangladesh',
      activation_type: 'Floods',
    },
    {
      map_event: {
        id: 3659,
        title: 'Satellite detected water extents between 11 and 15 August 2023 over South Sudan',
        image_file:
          '/static/unosat_filesystem/3659/UNOSAT_A3_Natural_Landscape_FL20220424SSD_11Aug_15Aug2023_SouthSudan_Week14.jpg',
        glide: 'FL20220424SSD',
        created_at: '2023-08-17T12:23:25.339493Z',
      },
      longitude: 29.858869499999997,
      latitude: 7.52578,
      area_event_name: 'South Sudan',
      activation_type: 'Floods',
    },
    {
      map_event: {
        id: 3655,
        title: 'UNOSAT Sentinel-1 Flood AI Monitoring Dashboard, Bangladesh',
        image_file: '/static/unosat_filesystem/3655/UNSAOT_FloodAIDashboard_FL20230807BGD.JPG',
        glide: 'FL20230807BGD',
        created_at: '2023-08-09T08:20:55.354273Z',
      },
      longitude: 90.44029,
      latitude: 23.596209,
      area_event_name: 'Bangladesh',
      activation_type: 'Floods',
    },
  ],
  countries: [
    'Afghanistan',
    'Albania',
    'Algeria',
    'American Samoa',
    'Andorra',
    'Angola',
    'Antigua and Barbuda',
    'Azerbaijan',
    'Argentina',
    'Australia',
    'Austria',
    'Bahamas (the)',
    'Bahrain',
    'Bangladesh',
    'Armenia',
    'Barbados',
    'Belgium',
    'Bermuda',
    'Bhutan',
    'Bolivia, Plurinational State of',
    'Bosnia and Herzegovina',
    'Botswana',
    'Brazil',
    'Belize',
    'Solomon Islands (the)',
    'Virgin Islands (British)',
    'Brunei Darussalam',
    'Bulgaria',
    'Myanmar',
    'Burundi',
    'Belarus',
    'Cambodia',
    'Cameroon',
    'Canada',
    'Cape Verde',
    'Cayman Islands (the)',
    'Central African Republic (the)',
    'Sri Lanka',
    'Chad',
    'Chile',
    'China',
    'Colombia',
    'Comoros',
    'Congo',
    'Congo (the Democratic Republic of the)',
    'Cook Islands',
    'Costa Rica',
    'Croatia',
    'Cuba',
    'Cyprus',
    'Czech Republic (the)',
    'Benin',
    'Denmark',
    'Dominica',
    'Dominican Republic (the)',
    'Ecuador',
    'El Salvador',
    'Equatorial Guinea',
    'Ethiopia',
    'Eritrea',
    'Estonia',
    'Faroe Islands (the)',
    'Falkland Islands (the) [Malvinas]',
    'Fiji',
    'Finland',
    'France',
    'French Guiana',
    'French Polynesia',
    'Djibouti',
    'Gabon',
    'Georgia',
    'Gambia (The)',
    'Palestine, State of',
    'Germany',
    'Ghana',
    'Gibraltar',
    'Kiribati',
    'Greece',
    'Greenland',
    'Grenada',
    'Guadeloupe',
    'Guam',
    'Guatemala',
    'Guinea',
    'Guyana',
    'Haiti',
    'Holy See (the) [Vatican City State]',
    'Honduras',
    'Hong Kong',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iran (the Islamic Republic of)',
    'Iraq',
    'Ireland',
    'Israel',
    'Italy',
    "Côte D'Ivoire",
    'Jamaica',
    'Japan',
    'Kazakhstan',
    'Jordan',
    'Kenya',
    "Korea (the Democratic People's Republic of)",
    'Korea (the Republic of)',
    'Kuwait',
    'Kyrgyzstan',
    "Lao People's Democratic Republic (the)",
    'Lebanon',
    'Lesotho',
    'Latvia',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Macao',
    'Madagascar',
    'Malawi',
    'Malaysia',
    'Maldives',
    'Mali',
    'Malta',
    'Martinique',
    'Mauritania',
    'Mauritius',
    'Mexico',
    'Monaco',
    'Mongolia',
    'Moldova (the Republic of)',
    'Montenegro',
    'Montserrat',
    'Morocco',
    'Mozambique',
    'Oman',
    'Namibia',
    'Nauru',
    'Nepal',
    'Netherlands (the)',
    'Aruba',
    'New Caledonia',
    'Vanuatu',
    'New Zealand',
    'Nicaragua',
    'Niger (the)',
    'Nigeria',
    'Niue',
    'Norway',
    'Northern Mariana Islands (the)',
    'Micronesia (the Federated States of)',
    'Marshall Islands (the)',
    'Palau',
    'Pakistan',
    'Panama',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines (the)',
    'Pitcairn',
    'Poland',
    'Portugal',
    'Guinea-Bissau',
    'Timor-Leste',
    'Puerto Rico',
    'Qatar',
    'Réunion',
    'Romania',
    'Russian Federation (the)',
    'Rwanda',
    'Saint Helena, Ascension and Tristan da Cunha',
    'Saint Kitts and Nevis',
    'Anguilla',
    'Saint Lucia',
    'Saint Pierre and Miquelon',
    'Saint Vincent and the Grenadines',
    'San Marino',
    'Sao Tome and Principe',
    'Saudi Arabia',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra Leone',
    'Singapore',
    'Slovakia',
    'Viet Nam',
    'Slovenia',
    'Somalia',
    'South Africa',
    'Zimbabwe',
    'Spain',
    'South Sudan',
    'Sudan (the)',
    'Western Sahara',
    'Suriname',
    'Swaziland',
    'Sweden',
    'Switzerland',
    'Syrian Arab Republic (the)',
    'Tajikistan',
    'Thailand',
    'Togo',
    'Tonga',
    'Trinidad and Tobago',
    'United Arab Emirates',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'Turks and Caicos Islands (the)',
    'Tuvalu',
    'Uganda',
    'Ukraine',
    'Macedonia (the former Yugoslav Republic of)',
    'Egypt',
    'United Kingdom of Great Britain and Northern Ireland (the)',
    'Isle of Man',
    'Tanzania, United Republic of',
    'United States (the)',
    'Virgin Islands (U.S.)',
    'Burkina Faso',
    'Uruguay',
    'Uzbekistan',
    'Venezuela, Bolivarian Republic of',
    'Wallis and Futuna',
    'Samoa',
    'Yemen',
    'Zambia',
    'Autonomous Province of Kosovo',
  ],
  activation_types: [
    'Cold Wave',
    'Complex Emergency',
    'Drought',
    'Earthquake',
    'Environmental Impact',
    'Epidemic',
    'Exercise / Simulation',
    'Extratropical Cyclone',
    'Extreme Temperature',
    'Famine',
    'Fire',
    'Flash Flood',
    'Floods',
    'Heat Wave',
    'Insect Infestation',
    'Landslides',
    'Mud Slide',
    'No glide',
    'Oil Spill',
    'Other',
    'Severe Local Storm',
    'Snow Avalanche',
    'Storm Surge',
    'Tech. Disaster',
    'Tornadoes',
    'Tropical Cyclone',
    'Tsunami',
    'Violent  Wind',
    'Volcano',
    'Wild Fire',
    'None',
    'None',
  ],
};
const Section = styled(ContentWrapper)`
  margin-top: 30px;
`;

const FiltersPanel = styled.div`
  background-color: ${colors.BLUE};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 20px;
  gap: 40px;
`;

const SearchField = styled.div`
  background-color: #f4f6f6;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 15px;
`;

const Button = styled.button`
  font-size: 16px;
  appearance: none;
  background-color: transparent;
  border: 0;
  font-family: inherit;
  outline: none;
  padding: 0;
  margin-left: 5px;
  color: #333;
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  cursor: pointer;
  display: flex;

  @media (min-width: ${screenSizes.medium.min}) {
    display: none;
  }
`;

const DividerSection = styled.div`
  &::before {
    content: '';
    display: block;
    border-bottom: 10px solid ${colors.BLUE};
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  background-color: ${colors.GRAY_LIGHT};

  @media (min-width: ${screenSizes.medium.min}) {
    padding: 40px;
  }
`;

const GridResults = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(min(300px, 400px), 1fr));

  @media (min-width: ${screenSizes.medium.min}) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (min-width: 1540px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

const Pagination = styled(ReactPaginate)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding-top: 30px;

  li:not(.pagination__link--disabled) {
    cursor: pointer;
  }

  li {
    list-style: none;
    margin: 0 20px;
  }

  .pagination__link--disabled {
    color: #bababa;
  }

  .pagination__link--active {
    color: ${colors.BLUE};
    font-weight: 600;
  }
`;

const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
`;

const NoResultsLabel = styled.h1`
  text-align: center;
`;

const Divider = styled.div`
  content: '';
  border-top: 2px solid ${colors.BLUE};
  width: 100%;
  margin-left: 20px;
`;

const ActivationText = styled.div`
  color: ${colors.BLUE};
  font-size: 16px;
  font-weight: 700;
  text-decoration: underline;
  white-space: nowrap;
`;

const ActivationTittle = styled.div`
  margin: 15px 20px;
  display: flex;
  align-items: center;
`;

const Description = styled.p`
  color: ${colors.BLUE};
  font-size: 18px;
  margin-left: 13px;

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 18px;
  }
`;

const ProductsPage = () => {
  const listResults = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [products, setProducts] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({
    date_from: formatDate(addMonths(new Date(), -6), 'yyyy-MM-dd'),
    date_to: formatDate(new Date(), 'yyyy-MM-dd'),
    is_charter: null,
  });

  const [currentPage, setCurrentPage] = useState(0);

  const PER_PAGE = 3;

  const offset = currentPage * PER_PAGE;

  const {
    isOpen: isModalOpen,
    handleClose: handleCloseModal,
    handleOpen: handleOpenModal,
  } = useToggle(false);

  useEffect(() => {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'X-Requested-With',
    };
    setIsLoading(true);
    axios
      .get('/our_products', { headers })
      .then((response) => {
        console.log("PAGE : ", response?.data)
        setData(response?.data);
        setProducts(response?.data.products);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        // setData(testObj);
        // setProducts(testObj.products);
        // setIsLoading(false);
      });
  }, []);

  const getUniqActivations = () => {
    const activations = [];
    products?.map(
      ({ map_event, area_event_name }) =>
        map_event.glide &&
        activations.push({
          activationName: map_event.glide,
          countryName: area_event_name,
        }),
    );

    return uniqBy(activations, 'activationName');
  };

  const currentPageData = getUniqActivations()
    ?.slice(offset, offset + PER_PAGE)
    .map((activation, index) => {
      const productResults = products?.filter(
        ({ map_event }) => map_event.glide === activation.activationName,
      );

      return (
        <div key={index}>
          <ActivationTittle>
            <ActivationText>
              {activation.countryName}, GLIDE NUMBER: {activation.activationName}
            </ActivationText>
            <Divider />
          </ActivationTittle>
          <GridResults productsLength={productResults.length}>
            {productResults.map((product, index) => (
              <MapCardItem
                key={`product-${index}`}
                data={product}
                mapData={product?.map_event}
                productsLength={productResults.length}
              />
            ))}
          </GridResults>
        </div>
      );
    });

  const pageCount = Math.ceil(getUniqActivations()?.length / PER_PAGE);

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
    listResults.current.scrollIntoView();
  };

  return (
    <>
      <section id="map-section">
        <Section>
          <SectionTitle text="UNOSAT ANALYSES" hasLine />
          <Description>
            In this map library, you can find all public analyses, data and other products produced
            by UNOSAT in response to humanitarian emergencies related to disasters, complex
            emergencies and conflict situations.
          </Description>
          <Description>
            Use the filters to curate the content on the map and the list below.
          </Description>
        </Section>
        <InformationalBanner color={colors.RED} />
        <FiltersPanel>
          <InlineFilters
            countries={data?.countries}
            activationTypes={data?.activation_types}
            initialFilters={selectedFilters}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            products={products}
            setProducts={setProducts}
            setIsLoading={setIsLoading}
            setCurrentPage={setCurrentPage}
          />
          <ButtonWrapper>
            <SearchField onClick={handleOpenModal}>
              <Sliders size={22} color="#909DA2" />
              <Button>Filters</Button>
            </SearchField>
          </ButtonWrapper>
        </FiltersPanel>
        {isLoading ? (
          <Loading>
            <LoadingDots />
          </Loading>
        ) : (
          <>
            {products && <MapResults products={products} />}
            <DividerSection />
            {products && (
              <Wrapper id="list-results" ref={listResults}>
                {products.length > 0 ? (
                  <>
                    <div>{currentPageData}</div>
                    <Pagination
                      previousLabel={<CaretLeft size={16} />}
                      nextLabel={<CaretRight size={16} />}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      onPageChange={handlePageClick}
                      previousLinkClassName={'pagination__link'}
                      nextLinkClassName={'pagination__link'}
                      disabledClassName={'pagination__link--disabled'}
                      activeClassName={'pagination__link--active'}
                    />
                  </>
                ) : (
                  <NoResultsLabel>No results</NoResultsLabel>
                )}
              </Wrapper>
            )}
          </>
        )}
        <FiltersContainer
          isModalOpen={isModalOpen}
          handleCloseModal={handleCloseModal}
          countries={data?.countries}
          activationTypes={data?.activation_types}
          setSelectedFilters={setSelectedFilters}
          selectedFilters={selectedFilters}
          setProducts={setProducts}
          setIsLoading={setIsLoading}
          setCurrentPage={setCurrentPage}
        />
      </section>
      <ApplicationsSection />
    </>
  );
};

export default ProductsPage;
