import { useState } from 'react';
import styled from '@emotion/styled';
import Burger from '@animated-burgers/burger-squeeze';
import '@animated-burgers/burger-squeeze/dist/styles.css';
import screenSizes from '../../constants/screenSizes';
import MenuMobile from '../common/MenuMobile';

const Wrapper = styled.div`
  @media (min-width: ${screenSizes.large.min}) {
    display: none;
  }

  display: flex;
`;

const ToggleButton = styled.button`
  background: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  padding: 0;
`;

const Menu = styled.nav`
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 80px;
  right: 0;
  transition: 350ms;
  flex-direction: column;
  padding: 10px 20px;
`;

const NavigationMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Wrapper>
      <ToggleButton onClick={toggle}>
        <Burger isOpen={isMenuOpen}></Burger>
      </ToggleButton>
      {isMenuOpen && (
        <Menu>
          <MenuMobile variant="inverted" size="large" />
        </Menu>
      )}
    </Wrapper>
  );
};

export default NavigationMenu;
