export const urls = {
  UNITAR_HOME: 'https://www.unitar.org/',
  UNITAR_PROJECTS:
    'https://www.unitar.org/sustainable-development-goals/united-nations-satellite-centre-UNOSAT/our-portfolio',
  UNITAR_ABOUT_US: 'https://unitar.org/about',
  LINKEDIN: 'https://ch.linkedin.com/company/unosat',
  TWITTER: 'https://twitter.com/unosat',
  FACEBOOK: 'https://www.facebook.com/UNITAR.unosat/',
  YOUTUBE: 'https://www.youtube.com/channel/UCks87-v0xvtTAx55-2FgWfA',
  TERMS_OF_USE: 'https://www.unitar.org/legal',
  UNITAR_STORIES:
    'https://www.unitar.org/sustainable-development-goals/united-nations-satellite-centre-UNOSAT/stories',
  UNITAR_NEWS: 'https://www.unitar.org/about/news-stories/news?pillars=367',
  FEEDBACK: 'https://www.surveymonkey.com/r/UNOSAT_user_survey',
};
