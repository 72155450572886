import styled from '@emotion/styled';
import fallbackImage from '../../../assets/map-photo.png';
import formatDate from 'date-fns/format';
import { parseISO } from 'date-fns';
import { useHistory } from 'react-router';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const Image = styled.div`
  border-radius: 10px;
  width: 100%;
  height: 200px;
  background-image: url('${({ imageUrl }) => imageUrl && imageUrl}'), url(${fallbackImage});
  background-size: cover;
  background-repeat: no-repeat;
`;

const Date = styled.div`
  margin-top: 10px;
  color: gray;
`;

const Title = styled.div`
  margin-top: 8px;
  color: #333;
`;

const MoreMapsItem = ({ image_file, title, created_at, id }) => {
  const history = useHistory();

  const handleOnClick = () => {
    history.push({
      pathname: `/products/${id}`,
      state: { id },
    });
  };

  return (
    <Wrapper onClick={handleOnClick}>
      <Image imageUrl={image_file}/>
      <Date>{created_at && formatDate(parseISO(created_at), 'dd MMM yyyy')}</Date>
      <Title>{title}</Title>
    </Wrapper>
  );
};

export default MoreMapsItem;
