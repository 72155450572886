import styled from '@emotion/styled';
import ContentWrapper from '../../ContentWrapper';
import { FacebookLogo, LinkedinLogo, YoutubeLogo } from 'phosphor-react';
import MenuMobile from '../../common/MenuMobile';
import { routes } from '../../../constants/routes';
import { colors } from '../../../constants/colors';
import { urls } from '../../../constants/urls';
import { ReactComponent as TwitterLogo } from '../../../assets/X_twitter.svg';
import { ReactComponent as UnitarUnosatLogo } from '../../../assets/UNITAR_UNOSAT_Vertical_White_Version.svg';
import { useHistory } from 'react-router';
import screenSizes from '../../../constants/screenSizes';

const Wrapper = styled.div`
  background-color: ${colors.BLUE};
  color: #fff;
  justify-content: space-between;
  width: 100%;

  @media (min-width: ${screenSizes.medium.min}) {
    flex-direction: column-reverse;
  }
`;

const BottomWrapper = styled(ContentWrapper)`
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  align-items: center;
  border-top: 1px solid ${colors.GRAY_LIGHT};
`;

const CopyrightWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: #fff;
  padding-bottom: 20px;
  align-items: center;

  & div {
    display: flex;
    align-items: center;
  }

  & a {
    cursor: pointer;
    color: #fff;
    text-decoration: none;
    align-items: center;
  }
`;

const Separator = styled.span`
  margin: 0 10px;

  ::before {
    content: ' | ';
  }
`;

const Svg = styled.div`
  width: 80px;

  & svg {
    ${({ align }) => align && 'margin-top: -10px'};
  }
`;

const SvgX = styled.div`
  width: 18.75px;
  margin-top: 3px;
`;

const Logo = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
`;

const Social = styled.div`
  padding-bottom: 20px;
  width: 140px;
  display: flex;
  justify-content: space-around;
`;

const NewFooter = () => {

  return (
    <Wrapper>
      <BottomWrapper>
        <CopyrightWrapper>
          <div>
          <Logo>
              <Svg>
                <UnitarUnosatLogo />
              </Svg>
            </Logo>
          </div>
          <Separator />
          <a href={urls.TERMS_OF_USE} target="_blank" rel="noreferrer">
            Terms of Use
          </a>
        </CopyrightWrapper>
        <Social>
          <a href={urls.LINKEDIN} target="_blank" rel="noreferrer">
            <LinkedinLogo size={24} color="#fff" weight="fill" />
          </a>
          <a href={urls.TWITTER} target="_blank" rel="noreferrer">
            <SvgX>
              <TwitterLogo />
            </SvgX>
          </a>
          <a href={urls.FACEBOOK} target="_blank" rel="noreferrer">
            <FacebookLogo size={24} color="#fff" weight="fill" />
          </a>
          <a href={urls.YOUTUBE} target="_blank" rel="noreferrer">
            <YoutubeLogo size={24} color="#fff" weight="fill" />
          </a>
        </Social>
      </BottomWrapper>
    </Wrapper>
  );
};

export default NewFooter;
