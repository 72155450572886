import SectionTitle from '../../components/common/SectionTitle';
import styled from '@emotion/styled';
import { ReactComponent as UnosatLogo } from '../../assets/unosat-blue-logo.svg';
import { FacebookLogo, LinkedinLogo, TwitterLogo, YoutubeLogo } from 'phosphor-react';
import { colors } from '../../constants/colors';
import ContactUsForm from './ContactUsForm';
import screenSizes from '../../constants/screenSizes';
import { urls } from '../../constants/urls';
import { useState } from 'react';
import Notification from '../../components/common/Notification';
import ContentWrapper from '../../components/ContentWrapper';

const Section = styled.section`
  padding-top: 50px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding-top: 30px;

  @media (min-width: ${screenSizes.medium.min}) {
    padding-top: 70px;
    flex-direction: row;
  }
`;

const LogoAndSocialSection = styled.div`
  flex: 0 0 30%;

  @media (min-width: ${screenSizes.medium.min}) {
    margin-right: 10%;
  }
`;

const LogoWrapper = styled.div`
  display: none;

  @media (min-width: ${screenSizes.medium.min}) {
    display: block;
  }
`;

const SocialMedia = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 50px;

  & p {
    font-size: 14px;
  }
`;

const Logos = styled.div`
  @media (min-width: ${screenSizes.medium.min}) {
    & svg {
      margin-right: 30px;
    }
  }
`;

const FormSection = styled.div`
  flex: 0 0 60%;
`;

const ContactUsPage = () => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const handleOnErrorClose = () => {
    setShowErrorMessage(false);
  };

  return (
    <Section>
      <ContentWrapper>
        <SectionTitle hasLine text="CONTACT US" />
        <Content>
          <LogoAndSocialSection>
            <LogoWrapper>
              <UnosatLogo />
            </LogoWrapper>
            <SocialMedia>
              <p>Keep up with UNOSAT's work, follow us on:</p>
              <Logos>
                <a href={urls.LINKEDIN} target="_blank" rel="noreferrer">
                  <LinkedinLogo size={32} color={colors.BLUE} weight="fill" />
                </a>
                <a href={urls.TWITTER} target="_blank" rel="noreferrer">
                  <TwitterLogo size={32} color={colors.BLUE} weight="fill" />
                </a>
                <a href={urls.FACEBOOK} target="_blank" rel="noreferrer">
                  <FacebookLogo size={32} color={colors.BLUE} weight="fill" />
                </a>
                <a href={urls.YOUTUBE} target="_blank" rel="noreferrer">
                  <YoutubeLogo size={32} color={colors.BLUE} weight="fill" />
                </a>
              </Logos>
            </SocialMedia>
          </LogoAndSocialSection>
          <FormSection>
            {showSuccessMessage && (
              <Notification
                title="Your message has been submitted successfully"
                variant="confirmation"
              />
            )}
            {showErrorMessage && (
              <Notification
                title="Something went wrong, please try again"
                variant="warning"
                onClose={handleOnErrorClose}
              />
            )}
            <ContactUsForm
              setShowSuccessMessage={setShowSuccessMessage}
              setShowErrorMessage={setShowErrorMessage}
            />
          </FormSection>
        </Content>
      </ContentWrapper>
    </Section>
  );
};

export default ContactUsPage;
