import VideoHeader from './VideoHeader';
import EmergencyMapping from './EmergencyMapping';
import styled from '@emotion/styled';
import InformationalBanner from '../../components/common/InformationalBanner';
import ServicesPage from '../services/ServicesPage';
import NewsAndStories from './NewsAndStories';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const HomePage = () => (
  <Wrapper>
    <VideoHeader />
    <EmergencyMapping isButtonVisible />
    <InformationalBanner />
    <ServicesPage />
    <NewsAndStories />
  </Wrapper>
);
export default HomePage;
