import Filters from './Filters';
import Modal from '../Modal';
import styled from '@emotion/styled';
import axios from 'axios';
import { addMonths } from 'date-fns';
import formatDate from 'date-fns/format';

const ApplyFiltersButton = styled.button`
  cursor: pointer;
  outline: none;
  height: auto;
  border: none;
  display: flex;
  align-items: center;
  max-width: 200px;
  padding: 15px 20px;
  background-color: #333;
  color: #fff;
  border-radius: 5px;

  & span {
    font-size: 14px;
    font-weight: 600;
  }
`;

const FiltersContainer = ({
  isModalOpen,
  handleCloseModal,
  countries,
  activationTypes,
  setSelectedFilters,
  selectedFilters,
  setProducts,
  setIsLoading,
  setCurrentPage,
}) => {
  const handleApplyFiltersOnClick = () => {
    setIsLoading(true);
    const defaultDateFrom = formatDate(addMonths(new Date(), -12), 'yyyy-MM-dd');
    const defaultDateTo = formatDate(new Date(), 'yyyy-MM-dd');

    const countries = selectedFilters?.region?.reduce((acc, country) => {
      return [...acc, country.value];
    }, '');

    const activationTypes = selectedFilters?.activation_type?.reduce((acc, activationType) => {
      return [...acc, activationType.value];
    }, '');

    const postParams = {
      ...selectedFilters,
      date_from: selectedFilters.date_from || defaultDateFrom,
      date_to: selectedFilters.date_to || defaultDateTo,
      region: countries || undefined,
      activation_type: activationTypes || undefined,
      title: selectedFilters?.title || '',
      is_active: selectedFilters?.is_active || false,
    };

    axios
      .post('/our_products/', postParams)
      .then((response) => {
        setProducts(response.data);
        setIsLoading(false);
        setCurrentPage(0);
      })
      .catch((err) => {});
    handleCloseModal(true); 
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={handleCloseModal}
      onRequestClose={handleCloseModal}
      title="Filters"
      footer={
        <ApplyFiltersButton onClick={handleApplyFiltersOnClick}>APPLY FILTERS</ApplyFiltersButton>
      }
    >
      <Filters
        countries={countries}
        activationTypes={activationTypes}
        setSelectedFilters={setSelectedFilters}
        selectedFilters={selectedFilters}
      />
    </Modal>
  );
};

export default FiltersContainer;
