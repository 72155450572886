import styled from '@emotion/styled';
import { colors } from '../../../constants/colors';

const Wrapper = styled.div`
  margin: 30px 40px;
  box-shadow: 0 2px 10px ${colors.DARK_GRAY};
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
`;

const Logo = styled.img`
  object-fit: contain;
  width: 130px;
  height: 130px;
  background-color: ${({ isGDACS }) => isGDACS && colors.BLUE};
`;
const Text = styled.div`
  color: ${colors.DARK_BLUE};
  margin-top: 10px;
`;

const ApplicationItem = ({ platform_name, icon, link, isGDACS }) => {
  const handleOnClick = () => {
    window.open(link, '_blank');
  };

  return (
    <>
      <Wrapper onClick={handleOnClick}>
        <Logo src={icon} isGDACS={isGDACS} />
        <Text>{platform_name.toUpperCase()}</Text>
      </Wrapper>
    </>
  );
};

export default ApplicationItem;
