import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { ArrowFatUp } from 'phosphor-react';

const Button = styled.button`
  position: fixed;
  bottom: 120px;
  right: 20px;
  padding: 10px 15px;
  font-size: 16px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }

  @media (max-width: 768px) {
    bottom: 10px;
    right: 10px;
  }
`;

const scrollToTop = (duration) => {
  const start = window.scrollY;
  const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

  const documentHeight = document.body.scrollHeight;
  const windowHeight = window.innerHeight;
  const destinationOffset = 0;
  const destinationOffsetToScroll = Math.round(documentHeight - destinationOffset < windowHeight 
    ? documentHeight - windowHeight 
    : destinationOffset);

  if ('requestAnimationFrame' in window === false) {
    window.scroll(0, destinationOffsetToScroll);
    return;
  }

  const scroll = () => {
    const now = 'now' in window.performance ? performance.now() : new Date().getTime();
    const time = Math.min(1, ((now - startTime) / duration));
    const timeFunction = time * (2 - time);
    window.scroll(0, Math.ceil((timeFunction * (destinationOffsetToScroll - start)) + start));

    if (window.scrollY === destinationOffsetToScroll) {
      return;
    }

    requestAnimationFrame(scroll);
  };

  scroll();
};

const ButtonToTop = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const handleClick = () => {
    scrollToTop(500);
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <Button onClick={handleClick} style={{ display: visible ? 'block' : 'none' }}>
      <ArrowFatUp size={32} weight="fill" color="white" />
    </Button>
  );
};

export default ButtonToTop;
