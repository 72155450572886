import { Route as ReactRouterRoute, Redirect } from 'react-router-dom';

const Route = ({ component: Component, exact, path, props = {} }) => {
  const { redirectTo } = props;

  return (
    <ReactRouterRoute
      exact={exact}
      path={path}
      render={() =>
        Component ? <Component {...props} /> : <Redirect to={{ pathname: redirectTo }} />
      }
    />
  );
};

export default Route;
