import { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import { colors } from '../../constants/colors';
import { PaperPlaneTilt } from 'phosphor-react';
import Button from '../../components/common/Button';
import { contactForms } from '../../constants/contactForms';
import LoadingDots from '../../components/common/LoadingDots';
import axios from 'axios';

const isValidEmail =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const NameAndOrganization = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FormSelectionButtons = styled.fieldset`
  border: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 40px;
`;

const SelectionButton = styled.label`
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  max-width: 100px;
  padding: 0 20px;
  color: ${colors.BLUE};
  height: 60px;
  ${({ isSelected }) => isSelected && `border-bottom: 2px solid ${colors.BLUE};`};
  ${({ isSelected }) => !isSelected && `opacity: 0.6`};

  @media (min-width: 600px) {
    max-width: 200px;
  }
`;

const FieldsGroup = styled.fieldset`
  border: none;
  margin-bottom: 30px;
  padding-right: 0;
`;

const InputField = styled.input`
  border: none;
  border-bottom: ${({ hasError }) =>
    hasError ? `1px solid ${colors.RED}` : `1px solid ${colors.BLUE}`};
  width: 100%;
  height: 40px;
`;

const Text = styled.p`
  color: ${colors.BLUE};
`;

const MessageInput = styled.textarea`
  border: ${({ hasError }) => (hasError ? `1px solid ${colors.RED}` : `1px solid ${colors.BLUE}`)};
  width: 100%;
  height: 100px;
`;

const SubmitText = styled.span`
  margin-left: 10px;
`;

const Label = styled.label`
  flex: 0 0 45%;
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  align-self: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Description = styled.div`
  text-align: justify;
  color: ${colors.BLACK};
  margin-bottom: 20px;
  margin-left: 14px;
  line-height: 1.5;

  & a {
    color: ${colors.BLUE};
  }
`;

const NonVisibleInput = styled.input`
  display: none;
`;

const Error = styled.p`
  color: ${colors.RED};
`;

const Asterisk = styled.span`
  color: red;
`;

const ContactUsForm = ({ setShowSuccessMessage, setShowErrorMessage }) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      to_email: contactForms.EMERGENCY_MAPPING,
      from_email: '',
      email_subject: '',
      email_message: '',
    },
  });
  const [submitting, setSubmitting] = useState(false);
  const watchFormEmail = watch('to_email');

  const onSubmit = (data) => {
    setSubmitting(true);

    axios
      .post('/contact_us/', data)
      .then((response) => {
        setSubmitting(false);
        reset();
        setShowSuccessMessage(true);
      })
      .catch((err) => {
        setSubmitting(false);
        setShowErrorMessage(true);
      });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormSelectionButtons {...register('to_email')}>
        <SelectionButton isSelected={watchFormEmail === contactForms.EMERGENCY_MAPPING}>
          <Text>FOR EMERGENCY MAPPING</Text>
          <NonVisibleInput
            checked={watchFormEmail === contactForms.EMERGENCY_MAPPING}
            type="radio"
            value={contactForms.EMERGENCY_MAPPING}
            onChange={() => setValue('to_email', contactForms.EMERGENCY_MAPPING)}
          />
        </SelectionButton>
        <SelectionButton isSelected={watchFormEmail === contactForms.MEDIA}>
          <Text>FOR MEDIA</Text>
          <NonVisibleInput
            checked={watchFormEmail === contactForms.MEDIA}
            type="radio"
            value={contactForms.MEDIA}
            onChange={() => setValue('to_email', contactForms.MEDIA)}
          />
        </SelectionButton>
        <SelectionButton isSelected={watchFormEmail === contactForms.GENERAL}>
          <Text>FOR GENERAL ENQUIRIES</Text>
          <NonVisibleInput
            checked={watchFormEmail === contactForms.GENERAL}
            type="radio"
            value={contactForms.GENERAL}
            onChange={() => setValue('to_email', contactForms.GENERAL)}
          />
        </SelectionButton>
      </FormSelectionButtons>
      {watchFormEmail === contactForms.EMERGENCY_MAPPING && (
        <>
          <Description>
            To request an Emergency Mapping activation, please fill out the request form and send it
            via e-mail to{' '}
            <a href="mailto:emergencymapping@unosat.org">emergencymapping@unosat.org</a>. Requests can also be submitted to 24/7 hotline directly if no
            internet connection available for e-mail: <a href="tel:+41754114998"> +41754114998</a>
          </Description>
          <Description>
            For any other enquiries related to the emergency mapping service, please use the contact
            form below.
          </Description>
        </>
      )}
      <FieldsGroup disabled={submitting}>
        <NameAndOrganization>
          <Label>
            <Text>Name <Asterisk>*</Asterisk></Text>
            <InputField
              name="name"
              type="text"
              hasError={errors.name}
              {...register('name', { required: true })}
            />
            {errors.name && <Error>This field is required.</Error>}
          </Label>
          <Label>
            <Text>Organization <Asterisk>*</Asterisk></Text>
            <InputField
              type="text"
              name="organization"
              hasError={errors.organization}
              {...register('organization', { required: true })}
            />
            {errors.organization && <Error>This field is required.</Error>}
          </Label>
        </NameAndOrganization>
        <label>
          <Text>Email <Asterisk>*</Asterisk></Text>
          <InputField
            name="from_email"
            hasError={errors.from_email}
            {...register('from_email', { required: true, pattern: isValidEmail })}
          />
          {errors.from_email && errors.from_email.type === 'required' && (
            <Error>Must be valid email example@yourdomaine.com</Error>
          )}
          {errors.from_email && errors.from_email.type === 'pattern' && (
            <Error>Invalid email</Error>
          )}
        </label>
        <label>
          <Text>Subject <Asterisk>*</Asterisk></Text>
          <InputField
            name="email_subject"
            type="text"
            hasError={errors.email_subject}
            {...register('email_subject', { required: true })}
          />
          {errors.email_subject && <Error>This field is required.</Error>}
        </label>
        <label>
          <Text>Message <Asterisk>*</Asterisk></Text>
          <MessageInput
            name="email_message"
            type="text"
            hasError={errors.email_message}
            {...register('email_message', { required: true })}
          />
          {errors.email_message && <Error>This field is required.</Error>}
        </label>
      </FieldsGroup>
      <ButtonWrapper>
        {submitting ? (
          <LoadingDots />
        ) : (
          <Button type="submit">
            <PaperPlaneTilt size={18} weight="fill" />
            <SubmitText>SEND MESSAGE</SubmitText>
          </Button>
        )}
      </ButtonWrapper>
    </Form>
  );
};

export default ContactUsForm;
