import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import SiteHeader from './components/site-header/SiteHeader';
import Content from './components/routing/Routes';
import SiteFooter from './components/site-footer/SiteFooter';
import styled from '@emotion/styled';
import ButtonToTop from './components/ButtonToTop';
import NewFooter from './components/new-design/footer/NewFooter';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const MainSection = styled.main`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;

  * {
    box-sizing: border-box;
  }
`;

const StyledContent = styled.div`
  margin-top: 95px;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

const PlainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

function App() {

  return (
    <Wrapper>
      <MainSection role="main">
        <Router basename="/">
          <SiteHeader />
          <StyledContent>
            <Content />
          </StyledContent>
          <SiteFooter />
        </Router>
        <ButtonToTop />
      </MainSection>
    </Wrapper>

    // FOR TEST UI
    // <Wrapper>
    //   <MainSection role="main">
    //     <Router basename="/">
    //       <StyledContent>
    //         <Content />
    //       </StyledContent>
    //       <NewFooter />
    //     </Router>
    //     <ButtonToTop />
    //   </MainSection>
    // </Wrapper>

  );
}

export default App;
